import { useState, useEffect } from "react";
import "../../styles/tutors.css";
import "../../styles/tutor-courses.css";
import { SmallSearch } from "./SmallSearch";
import { useMediaQuery } from "react-responsive";
import { TitleBack } from "../TitleBack";

export default function MobileFilters(props) {
    const { handleChange, formData, ratingSelected, rateSelected, handleFilter } = props;
    // const { handleChange, formData, ratingSelected, rateSelected, animationClass, handleFilter } = props;
    const [coursesElement, setCoursesElement] = useState([]);
    const [languagesElement, setLanguagesElement] = useState([]);
    const [courses, setCourses] = useState(props.courses);
    const [languages, setLanguages] = useState(props.languages);

    const [ratingsOptions, setRatingsOptions] = useState([]);
    const [rateOptions, setRateOptions] = useState([]);

    const isSmallScreen = useMediaQuery({ query: "(max-width: 380px)"});

    // Ratings options
    useEffect(() => {
        setRatingsOptions(() => {
            return [<button key={0} id={0} value="" name="rating" onClick={handleChange} className={`choice-chip${ratingSelected[0] ? " selected-chip" : ""}`}>Any rating</button>,
                    <button key={5} id={5} value="5" name="rating" onClick={handleChange} className={`choice-chip${ratingSelected[5] ? " selected-chip" : ""}`}>5 Only</button>,
                    <button key={4} id={4} value="4" name="rating" onClick={handleChange} className={`choice-chip${ratingSelected[4] ? " selected-chip" : ""}`}>{isSmallScreen ? "Above 4" : "4 and above"}</button>,
                    <button key={3} id={3} value="3" name="rating" onClick={handleChange} className={`choice-chip${ratingSelected[3] ? " selected-chip" : ""}`}>{isSmallScreen ? "Above 3" : "3 and above"}</button>,
                    <button key={2} id={2} value="2" name="rating" onClick={handleChange} className={`choice-chip${ratingSelected[2] ? " selected-chip" : ""}`}>{isSmallScreen ? "Above 2" : "2 and above"}</button>,
                    <button key={1} id={1} value="1" name="rating" onClick={handleChange} className={`choice-chip${ratingSelected[1] ? " selected-chip" : ""}`}>{isSmallScreen ? "Above 1" : "1 and above"}</button>];
                    
        }) 
    }, [ratingSelected, handleChange, isSmallScreen]);

    // Hourly rate options
    useEffect(() => {
        setRateOptions(() => {
            return [<button key={0} id={0} value="" name="rate" onClick={handleChange} className={`choice-chip${rateSelected[0] ? " selected-chip" : ""}`}>Any rating</button>,
                    <button key={7} id={7} value="40" name="rate" onClick={handleChange} className={`choice-chip${rateSelected[7] ? " selected-chip" : ""}`}>{isSmallScreen ? "Below 40" : "40 and below"}</button>,
                    <button key={6} id={6} value="35" name="rate" onClick={handleChange} className={`choice-chip${rateSelected[6] ? " selected-chip" : ""}`}>{isSmallScreen ? "Below 35" : "35 and below"}</button>,
                    <button key={5} id={5} value="30" name="rate" onClick={handleChange} className={`choice-chip${rateSelected[5] ? " selected-chip" : ""}`}>{isSmallScreen ? "Below 30" : "30 and below"}</button>,
                    <button key={4} id={4} value="25" name="rate" onClick={handleChange} className={`choice-chip${rateSelected[4] ? " selected-chip" : ""}`}>{isSmallScreen ? "Below 25" : "25 and below"}</button>,
                    <button key={3} id={3} value="20" name="rate" onClick={handleChange} className={`choice-chip${rateSelected[3] ? " selected-chip" : ""}`}>{isSmallScreen ? "Below 20" : "20 and below"}</button>,
                    <button key={2} id={2} value="15" name="rate" onClick={handleChange} className={`choice-chip${rateSelected[2] ? " selected-chip" : ""}`}>{isSmallScreen ? "Below 15" : "15 and below"}</button>,
                    <button key={1} id={1} value="10" name="rate" onClick={handleChange} className={`choice-chip${rateSelected[1] ? " selected-chip" : ""}`}>{isSmallScreen ? "Below 10" : "10 and below"}</button>];
                    
        }) 
    }, [rateSelected, handleChange, isSmallScreen]);

    // Courses elements to render
    useEffect(() => {
       setCoursesElement(() => courses.map((course, id) => {
            return (
                <div className="courses-radio--container" key={id}>
                    <input 
                        type="radio" 
                        id={course.name} 
                        name="course" 
                        className="course-filter-radio" 
                        value={`${course.id}-${course.name}`} 
                        checked={formData.course === `${course.id}-${course.name}`} 
                        onChange={handleChange} 
                    />
                    <label htmlFor={course.name}>{course.name}</label>
                </div>
            )
        }))
    }, [courses, formData.course, handleChange]);

    // Languages elements to render
    useEffect(() => {
        setLanguagesElement(() => languages.map((language, id) => {
            return (
                <div className="courses-radio--container" key={id}>
                    <input 
                        type="radio" id={language.name} 
                        name="language" 
                        className="course-filter-radio" 
                        value={`${language.id}-${language.name}`} 
                        checked={formData.language === `${language.id}-${language.name}`} 
                        onChange={handleChange} 
                    />
                    <label htmlFor={language.name}>{language.name}</label>
                </div>
            )
        }))
    }, [languages, formData.language, handleChange])

    return (
        <div id="mobile-filter--container">
            <TitleBack 
                to="/tutors"
                onClick={handleFilter} 
                title={"Filters"}
            />
        
            <div id="mobile-filter--content">
            {/* <div id="mobile-filter--content" className={animationClass}> */}
                <h2 className="mobile-filter--title">Course</h2>
                <div id="courses-options--container">
                    <SmallSearch 
                        isSettings={false}
                        setCourses={setCourses}
                        isCourses={true}
                    />

                    <fieldset>
                        <div className="courses-radio--container" key={0}>
                            <input 
                                type="radio" 
                                value="" 
                                id={"All Courses"} 
                                name="course" 
                                className="course-filter-radio" 
                                defaultChecked={formData.course === ""} 
                                onChange={handleChange}
                            />
                            <label htmlFor={"All Courses"}>All Courses</label>
                        </div>
                        {coursesElement}
                    </fieldset>
                </div>

                <h2 className="mobile-filter--title">Rating</h2>
                <div id="ratings-options--container">
                    {ratingsOptions}
                </div>

                <h2 className="mobile-filter--title">Language</h2>
                <div id="languages-options--container">
                    <SmallSearch 
                        isSettings={false}
                        setLanguages={setLanguages}
                        isCourses={false}
                    />

                    <fieldset>
                        <div className="courses-radio--container" key={0}>
                            <input 
                                type="radio" 
                                value="" 
                                id={"All Languages"} 
                                name="language" 
                                className="course-filter-radio" 
                                defaultChecked={formData.language === ""}
                                onChange={handleChange} 
                            />
                            <label htmlFor={"All Languages"}>All Languages</label>
                        </div>
                        {languagesElement}
                    </fieldset>
                </div>

                <h2 className="mobile-filter--title">Hourly Rate</h2>
                <div id="rates-options--container">
                    {rateOptions}
                </div>
            </div>
        </div>
    )
}