import React, { memo } from "react";
import Calendar from 'react-calendar';
import dateHelpers from '../../helpers/dateHelpers';

export const CalendarBookingBox = memo(({ date, setDate, bookedDays, fullyBookedDays }) => {
    const { areDatesEqual } = dateHelpers;
    const today = new Date(Date.now());

    // Update the current clicked date on the calendar
    function handleCalendar(event) {
        setDate(new Date(event));
    }

    // Check if a date has some bookings
    const dateClass = (dateToFind) => {
        const fullyBookedClass = "react-calendar__tile--booked-red";
        const bookedClass = "react-calendar__tile--booked-green";
        
        if (bookedDays) {
            for (let i = 0; i < bookedDays.length; i++) {
                const toDate = new Date(bookedDays[i]);
                if (areDatesEqual(dateToFind, toDate)) return bookedClass;
            } 
        }
        
        if (fullyBookedDays) {
            for (let i = 0; i < fullyBookedDays.length; i++) {
                const toDate = new Date(fullyBookedDays[i]);
                if (areDatesEqual(dateToFind, toDate)) return fullyBookedClass;
            }
        }

        return "";
    }

    return (
        <Calendar 
            onChange={handleCalendar}
            minDate={new Date(today)}
            value={date}
            tileClassName={({ activeStartDate, date }) =>
                dateClass(date)
            }
        />
    )
})