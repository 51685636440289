import Home from "./components/Home/Home";
import Courses from "./components/Courses/Courses";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import About from "./components/About";
import Tutors from "./components/Tutors/Tutors";
import Contact from "./components/Contact";
import SignUp from "./components/SignInUp/SignUp";
import SignInPage from "./components/SignInUp/SignIn";
import ScrollToTop from "./animations/ScrollToTop";
import Booking from "./components/Booking/Booking";
import TutorSettings from "./components/Settings/TutorSettings";
import TutorCourses from "./components/Tutors/TutorCourses";
import ForgotPassword from "./components/SignInUp/ForgotPassword";
import VerificationCode from "./components/SignInUp/VerificationCode";
import NewPassword from "./components/SignInUp/NewPassword";
import PendingBookings from "./components/Booking/PendingBookings";
import MailConfirmation from "./components/SignInUp/MailConfirmation";
import FontLoader from "./FontLoader";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./styles/styles.css";
import "./styles/footer.css";
import "./styles/settings.css";
import "./styles/tutor-courses.css";
import "./styles/tutors.css";
import "./styles/contact.css";
import "./styles/about.css";
import "./styles/booking.css";
import "./styles/navbar.css";
import "./styles/animations.css";
import "./styles/calendar.css";
import "./styles/notifications.css";
import "./styles/faq.css";
import "./styles/errors.css";
import "./styles/courses.css";
import "./styles/subscription.css";
import "./styles/agreements.css";

import AllBookings from "./components/Booking/AllBookings";
import useLoaderContext from "./hooks/useLoaderContext";
import Loader from "./components/Loader";
import useAuthContext from "./hooks/useAuthContext";
import SuccessPayment from "./components/Subscription/SuccessPayment";
import FailPayment from "./components/Subscription/FailPayment";
import SubscriptionPlans from "./components/Subscription/SubscriptionPlans";
import ErrorBoundary from "./components/ErrorBoundary";
import useErrorContext from "./hooks/useErrorContext";
import { Error } from "./components/Errors/Error";
import PrivacyPolicy from "./components/Agreements/PrivacyPolicy";
import TermsOfServices from "./components/Agreements/TermsOfServices";


export default function App() {
  const { token } = useAuthContext();
  const { isLoading } = useLoaderContext();
  const { isError } = useErrorContext();

  return (
    <div style={{ width: "100vw", height: "100%" }}>
      <HashRouter>
      <ErrorBoundary>
          {isLoading && <Loader />}
          <ScrollToTop />
          <FontLoader />
          <ToastContainer
              hideProgressBar={false}
              draggable
              theme="colored"
              position="bottom-left"
          />
          {isError ? (
            <Routes>
              <Route path="*" element={<Error />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/about" element={<About />} />
              <Route path="/tutors" element={<Tutors />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/sign-up" element={!token ? <SignUp /> : <Navigate to="/" />} />
              <Route path="/sign-in" element={!token ? <SignInPage /> : <Navigate to="/" />} />

              <Route path="/tutor/courses/:tutorID" element={<TutorCourses />} />
              <Route path="/tutor/bookings/:tutorID" element={<Booking />} />

              <Route path="/advanced-settings" element={<TutorSettings />} />
              <Route path="/all-bookings" element={<AllBookings />} />
              <Route path="/pending-bookings" element={<PendingBookings />} />

              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/verification-code" element={<VerificationCode />} />
              <Route path="/new-password" element={<NewPassword />} />
              <Route path="/mail-confirmation" element={<MailConfirmation />} />
              
              <Route path="/payment-success" element={<SuccessPayment />} />
              <Route path="/payment-failure" element={<FailPayment />} />
              
              <Route path="/subscription-plans" element={<SubscriptionPlans />} />

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-services" element={<TermsOfServices />} />

              <Route path="*" element={
                <Error 
                  errorTitle="Oops! 404 Not Found"
                  errorMessage="We can't seem to find the page you're looking for."
                  is404={true}
                />} 
              />
            </Routes>
          )}
        </ErrorBoundary>
      </HashRouter>
    </div>
  );
}