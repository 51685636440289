import React, { useState, useEffect, memo } from "react";
import useAuthContext from "../hooks/useAuthContext";
import useUserInfoContext from "../hooks/useUserInfoContext";
import 'react-toastify/dist/ReactToastify.css';
import toastHelpers from "../helpers/toastHelper";
import useErrorContext from "../hooks/useErrorContext.js";
import useLoaderContext from "../hooks/useLoaderContext.js";
import apiPrivate from "../apiPrivate.js";
import ratingHelpers from "../helpers/ratingHelpers.js";

export const StarRating = memo(({ tutorId, isEditRating, setIsEditRating, tutorInfo, setTutorInfo }) => {
    const { token } = useAuthContext();
    
    const { user:userInfo, isTutor } = useUserInfoContext();
    const { dispatch:errorDispatch } = useErrorContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    const { toastError, toastSuccess } = toastHelpers;
    const { prepareStarsDisplay } = ratingHelpers;

    const halfStar = "star_half";
    const fullStar = "star";
    const emptyStarClass = "star-rating material-symbols-outlined";
    const filledStarClass = "star-rating material-symbols-outlined filled-star";
    const [isSignedIn, setIsSignedIn] = useState(null);

    const [starClass, setStarClass] = useState([]);
    const [starName, setStarName] = useState([]);
    const [currRating, setCurrRating] = useState({});
    const [hasPrevRating, setHasPrevRating] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    const [message, setMessage] = useState("");
    const [error, setError] = useState(null);
    const errorMessage = "There was a problem updating your rating. Please try again.";

    const [rating, setRating] = useState(0);
    const [numOfRatings, setNumOfRatings] = useState(0);

    const [starsDisplay, setStarsDisplay] = useState([]);

    // Sync the rating and the number of rating with the tutorInfo
    useEffect(() => {
        if (tutorInfo) {
            setRating(tutorInfo.rating.rating);
            setNumOfRatings(tutorInfo.rating.num_of_ratings);
        }
    }, [tutorInfo]);
    
    // Check whether the user is signed in or not
    useEffect(() => {
        setIsSignedIn(token);
    }, [token]);

    // Prepare the stars display
    useEffect(() => {
        if (rating) {
            setStarsDisplay(prepareStarsDisplay(rating));
        }
    }, [prepareStarsDisplay, rating])

    // Update the rating
    const updateRating = async (newRating) => {
        try {
            await apiPrivate.post("/user/rateTutor", 
                {
                    "tutor_id": tutorId,
                    "rating": newRating
                });

            const newTotal = rating * numOfRatings - currRating.rating + newRating;
            const newNumOfRatings = hasPrevRating ? numOfRatings : numOfRatings + 1;
            setHasPrevRating(true);
            setTutorInfo(prev => {
                return {
                    ...prev,
                    rating: {
                        ...prev.rating,
                        rating: newTotal / newNumOfRatings,
                        num_of_ratings: newNumOfRatings
                    }
                }
            })
        } catch (error) {
            setError(errorMessage);
        }
    }    
    
    // Delete the rating
    const deleteRating = async () => {
        try {
            await apiPrivate.delete("/user/deleteRating", 
                {
                    data: {
                        tutor_id: tutorId
                    }
                }
            );

            const newNumOfRatings = numOfRatings - 1;
            const newTotal = newNumOfRatings === 0 ? 0 : rating * numOfRatings - currRating.rating;

            setTutorInfo(prev => {
                return {
                    ...prev,
                    rating: {
                        ...prev.rating,
                        rating: newTotal === 0 ? newTotal : newTotal / newNumOfRatings,
                        num_of_ratings: newNumOfRatings
                    }
                }
            })
        } catch (error) {
            setError(errorMessage);
        }
    }
    
    useEffect(() => {
        // Get the previous rating
        const getRating = async () => {
            try {
                const { data } = await apiPrivate.get(`/user/getRating?tutor_id=${tutorId}`);
                setIsUpdated(false);
                setCurrRating(data);
                setHasPrevRating(data.is_rated);
            } catch (error) {   
                loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
            }
        }

        if (isSignedIn) getRating();
    }, [isUpdated, isSignedIn, token, tutorId, loadingDispatch, errorDispatch])

    useEffect(() => {
        if (!isSignedIn || !hasPrevRating) {
            setStarClass(() => new Array(5).fill(emptyStarClass));
            setStarName(() => new Array(5).fill(fullStar));
        } else {
            let starsArr = new Array(5).fill(fullStar), rating = currRating.rating, classArr = new Array(5).fill(emptyStarClass);
            
            for (let i = 0; i < 5 && rating > 0; i++) {
                if (rating < 1) {
                    starsArr[i] = halfStar;
                    classArr[i] = filledStarClass;
                } else {
                    starsArr[i] = fullStar;
                    classArr[i] = filledStarClass;
                }
    
                rating -= 1;
            }

            setStarClass(classArr);
            setStarName(starsArr);
        }
    }, [currRating, hasPrevRating, isSignedIn]);

    // Adding a star UI
    function handleMouseEnter(event) {
        const currStar = event.target.getAttribute("data-index");

        if (!hasPrevRating) {
            setStarClass((prev) => {
                const copy = [...prev];

                for (let i = 0; i <= currStar; i++) {
                    copy[i] = filledStarClass;
                }
            
                return copy;
            })
        }
    }

    // Removing a star UI
    function handleMouseLeave(event) {
        const currStar = event.target.getAttribute("data-index");
        
        if (!hasPrevRating) {
            setStarClass((prev) => {
                const copy = [...prev];
                
                for (let i = 0; i <= currStar; i++) {
                    copy[i] = emptyStarClass;
                }
            
                return copy;
            })
        }
    }

    // Handle the click of a star
    function handleClick(event) {
        if (isSignedIn) {
            const curr = event.target.getAttribute("data-index");
            setIsUpdated(true);

            if (Number(curr) + 1 === Number(currRating.rating)) {
                setMessage("Rating deleted");
                deleteRating();
            } else {
                if (isTutor && userInfo.id === tutorId) {
                    toastError("You cannot rate yourself");
                } else {
                    setMessage(`Rating updated to ${Number(curr) + 1} stars`);
                    updateRating(Number(curr) + 1);
                }
            }
        }
    }

    
    useEffect(() => {
        if (message !== "") toastSuccess(message);
    }, [message, toastSuccess]);

    useEffect(() => {
        if (error) toastError(errorMessage);
    }, [error, toastError]);

    return (
        <div id="star-rating--container">
            {isEditRating ?
                <div className="flex-row tutor-rating--container" style={{ marginBottom: "10px" }}>
                    <span key={1} className={starClass[0]} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} data-index={0}>{starName[0]}</span>
                    <span key={2} className={starClass[1]} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} data-index={1}>{starName[1]}</span>
                    <span key={3} className={starClass[2]} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} data-index={2}>{starName[2]}</span>
                    <span key={4} className={starClass[3]} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} data-index={3}>{starName[3]}</span>
                    <span key={5} className={starClass[4]} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} data-index={4}>{starName[4]}</span>
                </div>
                :
                <div className="tutor-rating-info--container">
                    <div className={`tutor-rating--container tutor-courses-rating--container${rating === 0 ? "" : " rating-shown"}`}>
                        {rating === 0 ? <p>No rating available</p> : <p>{Number(rating).toFixed(2)}</p>}
                        {rating !== 0 && 
                            <div className="stars--container stars--container-tutor-courses">
                                {starsDisplay}
                            </div>
                        }
                    </div>
                    <p className="num-of-ratings--p">{`(${numOfRatings} review${numOfRatings !== 1 ? "s" : ""})`}</p>
                </div>
            }
            {isSignedIn && 
                <p 
                    id="rating-text" 
                    className="light-grey"
                    onClick={() => setIsEditRating(prev => !prev)}
                >
                    {isEditRating ? "Done" : hasPrevRating ? "Edit your rating" : "Add your rating"}
                </p>}
            <hr id="rating-hr" style={{ marginTop: isSignedIn ? "0" : "15px"}}></hr>
        </div>
    )
})