import { useEffect } from "react";
import { Navbar } from "../Navbar";
import HomeIntro from "./HomeIntro";
import Majors from "./Majors";
import HowItWorks from "./HowItWorks";
import Benefits from "./Benefits";
import { Footer } from "../Footer";
import RevealOnScroll from "../../animations/RevealOnScroll";
import FAQ from "./FAQ";
import useLoaderContext from "../../hooks/useLoaderContext";
import useErrorContext from "../../hooks/useErrorContext";
import useMajorsContext from "../../hooks/useMajorsContext";
import api from "../../api";
import MajorsCarousel from "./MajorsCarousel";

export default function Home() {
    const { dispatch:loadingDispatch } = useLoaderContext();
    const { dispatch:errorDispatch } = useErrorContext();
    const { majors, dispatch:majorsDispatch } = useMajorsContext();

    // Fetch the majors if not already fetched
    useEffect(() => {
        const fetchMajors = async () => {
            try {
                loadingDispatch({ type: 'LOAD' });
                const res = await api.get('/getMajors/');
                const { data } = res;
                
                majorsDispatch({ type: 'SET_MAJORS', payload: data });
                loadingDispatch({ type: 'NO_LOAD' });
            } catch (error) {
                loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
            }
        }

        if (!majors) fetchMajors();
    }, [loadingDispatch, majors, majorsDispatch, errorDispatch]);

    return (
        <div className="full-width-height">
            <div className="flex-column">
                <Navbar 
                    active="home" 
                />
                <HomeIntro />
                <div id="home--container" className="page-container">
                    <Majors />
                    <MajorsCarousel />
                    <HowItWorks />
                    <RevealOnScroll once={true}>
                        <Benefits />
                    </RevealOnScroll>
                    <FAQ />
                </div>
                <Footer />
            </div>
        </div>
    )
}