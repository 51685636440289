import React from 'react';

export default function ChooseFileButton({ handleProfileClick, fileRef, originalPicture, fileUpload }) {
    function handleClick(event) {
        event.preventDefault();
        fileRef.current.click();
    }

    return (
        <div className="choose-file--container">
            <input 
                type="file" 
                onChange={handleProfileClick}
                name="profile_picture" 
                ref={fileRef}
                style={{ display: "none" }}
                accept=".jpg,.png,.jpeg,.webp"
                data-testid="file-input"
            />

            {((!originalPicture && !fileUpload) || fileUpload === "") &&
                <div className="choose-file-button--container">
                    <button onClick={handleClick}>Choose a file</button>
                    <p>{fileUpload ? fileUpload.name : "No file chosen" }</p>
                </div>
            }
        </div>
    )
}