import { useState, useEffect, memo } from 'react';
import "../styles/footer.css"; 
import { useMediaQuery } from 'react-responsive';
import useAuthContext from '../hooks/useAuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
// import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import useLogout from '../hooks/useLogout';
import studytronLogo from "../images/studytron logo thick white.webp";

export const Footer = memo(() => {
    const { token } = useAuthContext();

    const [isSignedIn, setIsSignedIn] = useState(token);
    const { logout } = useLogout();
    
    const isMobile = useMediaQuery({ query: '(max-width: 415px)' });

    useEffect(() => {
        setIsSignedIn(token);
    }, [token]);

    return (
        <footer id="footer--container">
            <div className="footer-socials--container">
                <hr className="footer-hr"></hr>
                <div className="socials-icons--container">
                    {/* <FontAwesomeIcon icon={faTiktok} className="facebook-icon socials-icon" /> */}
                    <a href="https://www.facebook.com/share/15gGscq3Jr/?mibextid=wwXIfr">
                        <FontAwesomeIcon icon={faFacebookF} className="facebook-icon socials-icon" />
                    </a>
                    <a href="https://www.instagram.com/studytronlb">
                        <FontAwesomeIcon icon={faInstagram} className="socials-icon" />
                    </a>
                    <a href="mailto:studytron01@gmail.com" aria-label="Send an email to Studytron">
                        <FontAwesomeIcon icon={faEnvelope} className="socials-icon" />
                    </a>
                </div>
                <hr className="footer-hr"></hr>
            </div>

            <div className="studytron--container-footer">
                <Link to="/">
                    <img 
                        src={studytronLogo} 
                        style={{ height: "29px", width: "26px" }} 
                        alt="Studytron Logo"
                        className="studytron-logo--footer"
                    ></img>
                </Link>
                <h2 className="footer-website-name">Studytron</h2>
            </div>

            <div className="footer-links--container">
                <Link to="/" className="footer-link">Home</Link>
                <Link to="/about" className="footer-link">{`About${isMobile ? "" : " Us"}`}</Link>
                <Link to="/contact" className="footer-link">{`Contact${isMobile ? "" : " Us"}`}</Link>
                {isSignedIn ? 
                    <button onClick={logout} className="footer-link no-borders">Log Out</button>
                    :    
                    <Link to="/sign-up" className="footer-link no-borders">Sign Up</Link>
                }
            </div>
        </footer>
    );
})
