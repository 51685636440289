import React, { useMemo, useEffect, useState } from "react";
import Box from "../Box";
import { Link } from "react-router-dom";
import RevealOnScroll from "../../animations/RevealOnScroll";
import useMajorsContext from "../../hooks/useMajorsContext";

export default function Majors() {
    const show = useMemo(() => ["Show All", "+"], []);
    const hide = useMemo(() => ["Show Less", "-"], []);
    
    const [majorsElement, setMajorsElement] = useState([]);
    const [showAllButton, setShowAllButton] = useState(show)
    const [showAll, setShowAll] = useState();
    
    const { majors } = useMajorsContext();

    useEffect(() => {
        setShowAll(false);
    }, []);

    // Handle the number of courses showing
    useEffect(() => {
        if (majors) {
            let majorsArr;
    
            if (!showAll) {
                majorsArr = [...majors.slice(0,8)];
                setShowAllButton(show);
            } else {
                majorsArr = [...majors]
                setShowAllButton(hide);
            }
    
            setMajorsElement(majorsArr.map((major, id) => {
                return (
                    <RevealOnScroll key={id} once={true}>
                        <Link to="/courses" state={{ major: major, majorId: major.id }}>
                            <Box 
                                name={major.name}
                                abbreviation={major.code}
                                elementName={`Course${major.num_courses !== 1 ? "s" : ""}`}
                                elements={major.num_courses}
                                extraClass=""
                            />
                        </Link>
                    </RevealOnScroll>
                )
            }));
        }
    }, [showAll, majors, show, hide]);

    function handleClick(event) {
        event.preventDefault();
        setShowAll(prev => !prev);
    }
    

    return (
        <div id="majors--container">
            <div id="majors-elements">
                {majorsElement}
            </div>
            <button className="small-button" onClick={handleClick}>
                <p>{showAllButton[0]}</p>
                <p id="show-all-p">{showAllButton[1]}</p>
            </button>
        </div>
    )
}