import React, { useState, useEffect, memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import GeneralSettings from "./Settings/GeneralSettings";
import useAuthContext from "../hooks/useAuthContext";
import useOverflowContext from "../hooks/useOverflowContext";
import useBookingContext from "../hooks/useBookingsContext";
import useSubscriptionContext from "../hooks/useSubscriptionContext";
import useUserInfoContext from "../hooks/useUserInfoContext";
import useLoaderContext from "../hooks/useLoaderContext";

export const Navbar = memo(({ active, additionalStyle }) => {
    const { dispatch:overflowDispatch } = useOverflowContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    const { tutorBookings, userBookings } = useBookingContext();
    const { isTutor } = useUserInfoContext();
    const { token } = useAuthContext();
    const { isAccountActivated, remainingDays, remainingHours } = useSubscriptionContext();

    const [showSettings, setShowSettings] = useState(false);
    const [expandNavbar, setExpandNavbar] = useState(false);

    const isMobile = useMediaQuery({ query: '(max-width: 816px)' });
    const isSmallMobile = useMediaQuery({ query: '(max-width: 435px)' });

    const { pathname:currentRoute } = useLocation();

    const [isBookings, setIsBookings] = useState(null);

    const [activatedAccountComponent, setActivatedAccountComponent] = useState(null);

    useEffect(() => {
        loadingDispatch({ type: 'LOAD' });
        if (isAccountActivated !== null) {
            if (!isAccountActivated) {
                setActivatedAccountComponent(<span className="material-symbols-outlined settings-icon" style={{ fontSize: "24px" }}>
                    credit_card
                </span>);
            } else if (remainingDays !== null || remainingHours !== null) {
                if (!remainingHours) {
                    setActivatedAccountComponent(`${remainingDays} Days Left`);
                } else {
                    setActivatedAccountComponent(`${remainingHours} Hour${remainingHours === 1 ? "" : "s"} Left`);
                }
            }
            loadingDispatch({ type: 'NO_LOAD' });
        }
        
        if (!token || !isTutor) {
            loadingDispatch({ type: 'NO_LOAD' });
        }
    }, [isAccountActivated, remainingDays, remainingHours, loadingDispatch, token, isTutor])

    useEffect(() => {
        setIsBookings((tutorBookings && tutorBookings.length !== 0) || (userBookings && userBookings.length !== 0));
    }, [userBookings, tutorBookings]);

    const navbarLinks = useMemo(() => {
        return (
            <div id="navbar--titles">
                <Link 
                    to="/" 
                    className={`navbar--title${active === "home" ? " navbar--title-active" : ""}`}
                    >Home
                </Link>
                <Link 
                    to="/courses" 
                    className={`navbar--title${active === "courses" ? " navbar--title-active" : ""}`} 
                    >Courses
                </Link>
                <Link 
                    to="/tutors" 
                    className={`navbar--title${active === "tutors" ? " navbar--title-active" : ""}`}
                    >Tutors
                </Link>
                <Link 
                    to="/about" 
                    className={`navbar--title${active === "about" ? " navbar--title-active" : ""}`}
                    >About
                </Link>
                <Link 
                    to="/contact" 
                    className={`navbar--title${active === "contact" ? " navbar--title-active" : ""}`}
                    >Contact
                </Link>
                {!token &&
                <Link 
                    to="/subscription-plans" 
                    className={`navbar--title`}
                >
                    Pricing
                </Link>}
                {token && isSmallMobile &&
                    <Link 
                        to="/subscription-plans" 
                        state={{ currentRoute }} 
                        style={{ display: "visible" }}
                        className={`navbar--title`}
                    >
                        Subscription
                    </Link>
                }
            </div>
        )
    }, [isSmallMobile, token, active, currentRoute]);

    function handleSettingsClick() {
        setShowSettings(prev => !prev);
    }

    function handleNavbarClick() {
        setExpandNavbar(prev => !prev);
    }

    // When the settings are open, the scrolling should be stopped
    useEffect(() => {
        overflowDispatch({ type: 'SET_FREEZE', payload: showSettings || expandNavbar });
    }, [showSettings, expandNavbar, overflowDispatch]);
    
    return (
        !isMobile ? 
        <nav id="navbar--container" style={{ ...additionalStyle }}>
            <div id="navbar-titles--container">
                {navbarLinks}
                {!token && 
                    <div id="sign-in-up--navbar">
                        <Link 
                            to="/sign-up" 
                            className= "sign-up-navBar"   
                        >Sign Up</Link>
                        <Link 
                            to="/sign-in" 
                            id="sign-in--button"
                            className="sign-up-navBar"   
                        >Sign In</Link>
                    </div>
                }
                {token && 
                    <div 
                        className="navbar-icons--container"
                        style={{ width: isTutor ? "280px" : "240px"}}
                    >
                        {isTutor && 
                        <Link 
                            to="/subscription-plans" 
                            state={{ currentRoute }} 
                            className={isAccountActivated ? "days-left--button" : "navbar-icon--container"}
                        >
                            {activatedAccountComponent}
                        </Link>}

                        <Link to="/pending-bookings" className="navbar-icon--container" style={{ marginBottom: "-2.6px" }}>
                            <span className="material-symbols-outlined settings-icon" style={{ fontSize: "22.5px" }}>
                                pending_actions
                            </span>
                        </Link>
                        <Link to="/all-bookings" className="navbar-icon--container">
                            <span className="material-symbols-outlined settings-icon">
                                {isBookings ? "event_available" : "calendar_today"}
                            </span>
                        </Link>
                        <span className="material-symbols-outlined settings-icon" onClick={handleSettingsClick}>
                            settings
                        </span>
                    </div>
                }

            </div>
            {showSettings && 
                <GeneralSettings 
                    setShowSettings={setShowSettings}
                    page={active}
                />}
        </nav> :
        <nav id="navbar--container" className={expandNavbar ? "fixed" : ""}>
            {token ?
                <div 
                    className="navbar-icons--container"
                    style={{ width: isTutor ? !isSmallMobile ? "260px" : "170px" : "170px"}}
                >
                    {isTutor && !isSmallMobile && 
                    <Link 
                        to="/subscription-plans" 
                        state={{ currentRoute }} 
                        className={isAccountActivated ? "days-left--button" : "navbar-icon--container"}
                    >
                        {activatedAccountComponent}
                    </Link>}

                    <Link to="/pending-bookings" className="navbar-icon--container">
                        <span className="material-symbols-outlined settings-icon" style={{ fontSize: "22.5px" }}>
                            pending_actions
                        </span>
                    </Link>
                    <Link to="/all-bookings" className="navbar-icon--container">
                        <span className="material-symbols-outlined settings-icon">
                            {isBookings ? "event_available" : "calendar_today"}
                        </span>
                    </Link>
                    <span className="material-symbols-outlined settings-icon" onClick={handleSettingsClick}>
                        settings
                    </span>
                </div>
                :
                <Link 
                    to="/sign-in" 
                    className="sign-up-navBar"  
                >Sign In</Link>
            }
            <div className={`navbar-icon${expandNavbar ? " change" : ""}`} onClick={handleNavbarClick}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
            {true &&
                <div id="navbar-background" className={expandNavbar ? "slide-in--transform" : ""}>
                    {navbarLinks}
                </div>
            }
            {showSettings && 
                <GeneralSettings 
                    setShowSettings={setShowSettings}
                    page={active}
                />}
        </nav>
    )
})