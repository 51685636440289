import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { StarRating } from "../StarRating";
import { TitleBack } from "../TitleBack";
import useLoaderContext from "../../hooks/useLoaderContext";
import useErrorContext from "../../hooks/useErrorContext";
import apiPrivate from "../../apiPrivate";

export default function TutorCourses() {
    const [search, setSearch] = useState("");
    const [tutorInfo, setTutorInfo] = useState(null);
    const [courses, setCourses] = useState([]);
    const [coursesElement, setCoursesElement] = useState([]);
    const { tutorID } = useParams();
    const [languagesString, setLanguagesString] = useState("");

    const { dispatch:loadingDispatch } = useLoaderContext();
    const { dispatch:errorDispatch } = useErrorContext();

    const [isEditRating, setIsEditRating] = useState(false);

    // Fetch the tutor's info
    useEffect(() => {
        const getTutorInfo = async () => {
            try {
                loadingDispatch({ type: 'LOAD' });
                const { data } = await apiPrivate.get(`/tutor/getInfo?user_id=${tutorID}`);
                loadingDispatch({ type: 'NO_LOAD' });
        
                setCourses(data.taught_courses);
                setTutorInfo(data);
            } catch (error) {
                loadingDispatch({ type: 'NO_LOAD' });
                
                if (error.response.status === 404) {
                    errorDispatch({ type: '404_ERROR', isNetworkError: false });
                } else {
                    errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                }
            } 
        }

        getTutorInfo();
    }, [errorDispatch, loadingDispatch, tutorID]);

    // Create the languages string
    useEffect(() => {
        if (tutorInfo) {
            setLanguagesString(tutorInfo.languages.map(language => language.name).join(', '));
        }
    }, [tutorInfo])

    useEffect(() => {
        setCoursesElement(() => {
            if (courses.length === 0) {
                return [];
            }

            return courses.map((course, id) => {
                return (
                    <div id="tutor-course-box" key={id}>
                     <p className="green-p-box medium-size">{`${course.major.code}${course.code}`}</p>
                        <div className="course-title">
                            <p>{course.name}</p>
                        </div>
                    
                        <Link 
                            className="small-button margin-less" 
                            to={`/tutor/bookings/${tutorInfo.user.id}`} 
                            state={{ course }}
                        >Book</Link>
                    </div>
                )
            })   
        })
    }, [courses, tutorInfo])

    useEffect(() => {
        if (tutorInfo) {
            setCourses(tutorInfo.taught_courses.filter((course) => course.name.toLowerCase().includes(search.toLowerCase())));
        }
    }, [search, tutorInfo])

    // Handle the search
    function handleSearch(event) {
        setSearch(event.target.value);
    }

    // Generate the languages string

    return (
        tutorInfo 
        && <div id="tutor-courses--container">   
            <TitleBack 
                title={`${tutorInfo.user.first_name} ${tutorInfo.user.last_name}`}
                to="/tutors"
            />         

            <div id="tutor-courses--box">
                <div id="tutor-info">
                    {tutorInfo.user.profile_picture ?
                        <img 
                            src={tutorInfo.user.profile_picture}
                            alt="Tutor Profile"
                            className="profile-picture profile-picture-img--tutor-courses"
                            style={{ width: "160px", height: "160px" }}
                            loading="lazy"
                        />
                        :
                        <span className="material-symbols-outlined" id="tutor-profile">account_circle</span>
                    }   
                    
                    <StarRating 
                        tutorId={tutorInfo.id}
                        tutorInfo={tutorInfo}
                        setTutorInfo={setTutorInfo}
                        isEditRating={isEditRating}
                        setIsEditRating={setIsEditRating}
                    />

                    <div className="tutor-info--tutorcourses">
                        <div id="email--container" className="tutor-courses--titles">
                            <p className="very-small-title">EMAIL:</p>
                            <a href={`mailto:${tutorInfo.user.email}`} className="email-link">{tutorInfo.user.email}</a>
                        </div>

                        <div id="description--container" className="tutor-courses--titles">
                            <p className="very-small-title">DESCRIPTION:</p>
                            <p className="light-grey regular">{tutorInfo.description}</p>
                        </div>

                        <div id="language--container" className="tutor-courses--titles">
                            <p className="very-small-title">SPEAKS:</p>
                            <p className="light-grey regular">{languagesString}</p>
                        </div>

                        <div id="hourly-rate--container" className="tutor-courses--titles">
                            <p className="very-small-title">HOURLY RATE:</p>
                            <div className="flex-row light-grey">
                                <p className="regular">USD&nbsp;</p>
                                <p className="bold">{tutorInfo.rate}</p>
                            </div>
                        </div>
                    </div>

                </div>
                    <div id="tutor-courses--content">
                        <div className="search--container no-absolute-transform" id="tutor-courses--search">
                            <div id="search-bar">
                                <input type="text" name="searchEntry" placeholder="Search Courses" onChange={handleSearch} value={search} />
                                <span className="material-symbols-outlined" id="search-icon">search</span>
                            </div>
                        </div>

                        {coursesElement.length === 0 ? <p className="small-grey margin-top">No course was found.</p> : <div id="tutor-courses">{coursesElement}</div>}
                    </div>
            </div>
        </div>
    )
}