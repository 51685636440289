import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import routingMappings from "../../data/routingMappings";
import { useMediaQuery } from "react-responsive";
import useLogout from "../../hooks/useLogout";
import BackArrow from "../BackArrow";
import validators from "../../helpers/validators";
import useUserInfoContext from "../../hooks/useUserInfoContext";
import ChooseFileButton from "../ChooseFileButton";
import useLoaderContext from "../../hooks/useLoaderContext.js";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import settingsHelpers from "../../helpers/settingsHelpers.js";

export default function GeneralSettings({ setShowSettings, active }) {
    const { 
        changeName, 
        changePassword, 
        changePhoneNumber, 
        uploadProfilePicture, 
        deleteProfilePicture,
        cropImage,
        compressImage
    } = settingsHelpers;
    const { isValidPassword, isValidNumber } = validators;
    const { logout } = useLogout();
    const { pathname:currentRoute } = useLocation();

    const { user:allUserInfo, isTutor, dispatch:userInfoDispatch } = useUserInfoContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    const userInfo = isTutor ? allUserInfo.user : allUserInfo;

    const errorMessage = "Something went wrong. Please try again.";
    const successMessage = "Changes saved successfully!";

    const [formData, setFormData] = useState({
        first_name : "",
        last_name: "",
        old_password: "",
        password: "",
        confirm_password: "",
        profile: {
            phone_number: ""
        }
    });
    const [originalForm, setOriginalForm] = useState(null);
    const [originalPicture, setOriginalPicture] = useState(null);
    const [fileUpload, setFileUpload] = useState(null);
    const [fileURL, setFileURL] = useState("");
    
    const [isClosed, setIsClosed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    
    const fileRef = useRef(null);
    const cropperRef = useRef(null);
    
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
    
    const [error, setError] = useState(null);

    useEffect(() => {
        if (userInfo) {
            const ogInfo = {
                first_name: userInfo.first_name,
                last_name: userInfo.last_name,
                profile: {
                    phone_number: userInfo.profile.phone_number || ""
                },
                old_password: "",
                password: "",
                confirm_password: "",
            };

            setOriginalForm({ ...ogInfo });
            setFormData({ ...ogInfo });

            setOriginalPicture(userInfo.profile_picture);
        }
    }, [userInfo]);

    // Go back to the home page
    function handleClick() {
        const time = isMobile ? 1500 : 900;
        setIsClosed(true);

        setTimeout(() => {
            setShowSettings(false);
            setIsClosed(false);
        }, time); 
    }

    // Handle the form change
    function handleChange(event) {
        setError(null);
        const { name, value } = event.target;

        if (name === "password") {
            const error = {};
            if (value !== "") {
                isValidPassword(value, "", error, false);
            }
            setError(error);
        }

        if (name === "phone_number") {
            setFormData((prev) => {
                return {
                    ...prev,
                    profile: {
                        [name]: value
                    }
                }
            })
        } else {
            setFormData((prev) => {
                return {
                    ...prev,
                    [name]: value
                }
            })
        }

    } 

    // Handle the saving of the form
    async function handleSave(event) {
        event.preventDefault();
        loadingDispatch({ type: 'LOAD' });

        const err = {};

        if (formData.first_name !== originalForm.first_name || formData.last_name !== originalForm.last_name) {
            if (formData.first_name === "" || formData.last_name === "") {
                err["name"] = "First name and last name must be filled.";
            } else {
                await changeName(originalForm, formData, userInfoDispatch, setOriginalForm);
            }
        } 
        
        if (formData.profile.phone_number !== originalForm.profile.phone_number) {
            if (isValidNumber(formData.profile.phone_number, err)) {
                await changePhoneNumber(userInfo, fileURL, formData, userInfoDispatch, setOriginalForm);
            }
        }

        if (fileUpload === "") {
            await deleteProfilePicture(userInfoDispatch, setOriginalPicture, setFileURL, setFileUpload);
        } else if (fileUpload) {
            await uploadProfilePicture(userInfoDispatch, fileUpload, fileURL, setOriginalPicture, setFileURL, setFileUpload);
        }
        
        if (formData.old_password === "" && formData.password === "" && formData.confirm_password === "") {
            // all three are not filled, I can ignore this case
            setError(err);
        } else if (formData.old_password === "" || formData.password === "" || formData.confirm_password === "") {
            // if there is at least one that is filled and the others are empty
            err["password"] = "All password fields must be filled.";
        } else if (!isValidPassword(formData.old_password, formData.confirm_password, err, false)) {
            // if the old password doesn't meet the requirement, we're sure it's not the one in the database
            err["old_password"] = "Incorrect password.";
        } else if (isValidPassword(formData.password, formData.confirm_password, err, true)) {
            await changePassword(formData, setShowSettings, logout);
        }
        
        setError(err);
        loadingDispatch({ type: 'NO_LOAD' });
    }

    // Handle the logout and reload
    function handleLogout() {
        logout();
        setShowSettings(false);
    }

    // Handle the change of the picture
    async function handleProfileClick(event) {
        loadingDispatch({ type: 'LOAD' });
        const file = event.target.files[0];
        if (file) {
            const finalFile = await compressImage(file);
            setFileUpload(finalFile);
            setFileURL(URL.createObjectURL(new Blob([finalFile], { type: finalFile.type })));
            setIsEdit(true);
        }
        loadingDispatch({ type: 'NO_LOAD' });
    }

    // Upload a file
    function handleFileUpload() {
        fileRef.current.click();
    }

    // Cancel the new profile
    function handleCancelProfile() {
        setIsEdit(false);
    }

    // Remove a profile
    function handleRemoveProfile() {
        fileRef.current.value = '';
        setFileURL("");
        setFileUpload(""); // this means that the profile was removed (else it would've been null)
    }

    // Handle the cropping of the image
    async function handleCrop() {
        const finalFile = await cropImage(cropperRef);
        setIsEdit(false);
        setFileUpload(finalFile);
        setFileURL(URL.createObjectURL(new Blob([finalFile], { type: finalFile.type })));
    }
    
    return (
        <div id="general-settings--container" className={`flex-column${isClosed ? " fade-out" : ""}`}>
            <div id="general-settings" className={isClosed ? "slide-out" : ""}>
                <div id="settings-title">
                    <BackArrow 
                        to={routingMappings[active]}
                        onClick={handleClick}
                    />
                    <h1 className="large-bold-title">Account Settings</h1>
                </div>

                <form>
                    <h3 className="settings-subtitle">Name</h3>
                    <div id="name-sign-up--container">
                        <div className="form-row sign-up--text">
                            <label htmlFor="first_name" className="label">First Name:</label>
                            <input 
                                type="text" 
                                id="first_name"
                                name="first_name" 
                                placeholder="Enter your first name" 
                                className={`sign-up--elements${error && error.name ? " error-borders" : ""}`}
                                onChange={handleChange} 
                                value={formData.first_name} 
                            />
                        </div>
                        <div className="form-row sign-up--text">
                            <label htmlFor="last_name" className="label">Last Name:</label>
                            <input 
                                type="text"
                                id="last_name" 
                                name="last_name" 
                                placeholder="Enter your last name" 
                                className={`sign-up--elements${error && error.name ? " error-borders" : ""}`} 
                                onChange={handleChange} 
                                value={formData.last_name} 
                            />
                        </div>
                    </div>
                    {error && error.name && <p className="error-p">{error.name}</p>}

                    <h3 className="settings-subtitle">Phone Number</h3>
                    <div id="name-sign-up--container">
                        <div className="form-row sign-up--text" style={{ width: "100%" }}>
                            <label htmlFor="phone_number" className="label">Phone Number:</label>
                            <input 
                                type="tel" 
                                id="phone_number"
                                name="phone_number" 
                                placeholder="Enter your phone number" 
                                className={`sign-up--elements${error && error.phone_number ? " error-borders" : ""}`}
                                onChange={handleChange} 
                                value={formData.profile.phone_number} 
                            />
                        </div>
                    </div>
                    {error && error.phone_number && <p className="error-p">{error.phone_number}</p>}
                    
                    <h3 className="settings-subtitle">Profile Picture</h3>
                    <div id="name-sign-up--container">
                        <div className="form-row sign-up--text" style={{ width: "100%" }}>
                        <div className="profile-img-signup--container">
                            {(fileURL || originalPicture) && fileUpload !== "" &&
                                (!isEdit ?
                                <div 
                                    className="profile-picture--no-edit-settings" 
                                    onClick={handleFileUpload} 
                                    style={{ backgroundImage: `url(${fileURL || originalPicture})` }}
                                />
                                :
                                <div className="cropper--container">
                                    <Cropper
                                        src={fileURL || originalPicture}
                                        style={{ height: 200, width: "100%" }}
                                        initialAspectRatio={1 / 1} 
                                        guides={false}
                                        responsive={true}
                                        ref={cropperRef}
                                        rotatable={false}
                                        dragMode="move"
                                        cropBoxMovable={false}
                                        cropBoxResizable={false}
                                        minCropBoxWidth={150}
                                        minCropBoxHeight={150}
                                        viewMode={3}
                                        center={false}
                                        highlight={false}
                                    />
                                    <div className="profile-circular-overlay" />
                                </div>
                                )
                            }

                            <ChooseFileButton 
                                handleProfileClick={handleProfileClick}
                                fileURL={fileURL}
                                originalPicture={originalPicture}
                                fileRef={fileRef}
                                fileUpload={fileUpload}
                            />

                            {(fileURL || originalPicture) && fileUpload !== "" && 
                                <div className="settings-profile-buttons--container">
                                    {isEdit && 
                                        <button 
                                            type="button" 
                                            onClick={handleCrop}
                                            className="edit-profile-settings"
                                        >Crop</button>
                                    }
                                    {!isEdit &&
                                    <button
                                        className="cancel-profile-settings"
                                        onClick={handleRemoveProfile}
                                        name="name"
                                    >
                                        Remove
                                    </button>}
                                    {isEdit && 
                                        <button
                                            className="cancel-profile-settings"
                                            onClick={handleCancelProfile}
                                            type="button"
                                        >
                                            Cancel
                                        </button>
                                    }
                                </div>
                            }
                            </div>
                        </div>
                    </div>

                    <h3 className="settings-subtitle">Password</h3>
                    <div className="form-row">
                        <label htmlFor="password" className="label">Old Password:</label>
                        <input 
                            type="password" 
                            id="password"
                            name="old_password" 
                            placeholder="Enter your old password" 
                            className={`sign-up--elements${error && (error.old_password) ? " error-borders" : ""}`} 
                            onChange={handleChange} 
                            value={formData.old_password} 
                        />
                    </div>

                    <div className="form-row">
                        <label htmlFor="new-password" className="label">New Password:</label>
                        <input 
                            type="password" 
                            id="new-password"
                            name="password" 
                            placeholder="Enter your new password" 
                            className={`sign-up--elements${error && error.password ? " error-borders" : ""}`} 
                            onChange={handleChange} 
                            value={formData.password} 
                        />
                    </div>

                    <div className="form-row">
                        <label htmlFor="confirm-password" className="label">Confirm Password:</label>
                        <input 
                            type="password" 
                            id="confirm-password"
                            name="confirm_password" 
                            placeholder="Confirm your new password" 
                            className={`sign-up--elements${error && error.password ? " error-borders" : ""}`} 
                            onChange={handleChange} 
                            value={formData.confirm_password} 
                        />
                    </div>
                    {error && (error.old_password || error.password) && <p className="error-p">{error.old_password || error.password}</p>}

                    <div id="buttons-settings--container">
                        <div id="save-signout--container">
                            <button type="button" className="small-button" onClick={handleSave}>Save Changes</button>
                            <button type="button" className="small-button" id="red-button" onClick={handleLogout}>Sign Out</button>
                        </div>
                        {isTutor && <Link 
                            className="small-button" 
                            id="grey-button"
                            to="/advanced-settings"
                            state={{ errorMessage, successMessage, currentRoute }}
                        >Advanced Settings</Link>}
                    </div>
                </form>
            </div>
        </div>
    );
}