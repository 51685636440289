import { useInView } from "framer-motion"; // Framer Motion hook
import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";

export default function Box(props) {
    const { name, abbreviation, elementName, elements, extraClass, setActiveIndex = () => {}, idx } = props;

    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 0.85 });

    useEffect(() => {
        if (isInView) {
            setActiveIndex(idx);
        }
    }, [isInView, idx, setActiveIndex]);

    const isSmallMobile = useMediaQuery({ query: "(max-width: 410px)" });

    return (
        <div className={`small-box${extraClass}`} ref={ref}>
            <p className="green-p-box">{abbreviation}</p>
            <h2 className="light-title element-info-h2">{name}</h2>
            {!isSmallMobile && (
                <p className="light-grey element-info-p">{`${elements} ${elementName}`}</p>
            )}
        </div>
    );
}
