import React, { createContext, useReducer, useState } from "react"

const initialState = {
    isAccountActivated: null,
    isTrialUsed: null,
    remainingDays: null,
    remainingHours: null
};

export const SubscriptionContext = createContext();

const subscriptionReducer = (state, action) => {
    switch(action.type) {
        case 'SET_STATUS':
            return {
                ...state,
                isAccountActivated: action.payload.account_is_activated,
                isTrialUsed: action.payload.trial_was_used
            }

        case 'SET_REMAINING_DAYS':
            const amountLeft = action.payload;

            if (amountLeft.hours_left && amountLeft.hours_left !== "None") {
                return {
                    ...state,
                    remainingHours: Number(amountLeft.hours_left)
                }
            } else {
                return {
                    ...state,
                    remainingDays: Number(amountLeft.days_left)
                }
            }

        case 'RESET_SUBSCRIPTION':
            return {
                ...initialState
            }

        default:
            return state;
    }
} 

export const SubscriptionContextProvider = ({ children }) => {
    const [subscriptionDetails, dispatch] = useReducer(subscriptionReducer, initialState);
    const [reloadSubscription, setReloadSubscription] = useState(true);

    return (
        <SubscriptionContext.Provider value={{ ...subscriptionDetails, dispatch, reloadSubscription, setReloadSubscription }}>
            {children}
        </SubscriptionContext.Provider>
    )
}