import PackageContainer from "./PackageContainer";
import packages from "../../data/packages";
import { useEffect, useState } from "react";
import BackArrow from "../BackArrow";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { TitleBack } from "../TitleBack";
import useSubscriptionContext from "../../hooks/useSubscriptionContext";
import useOverflowContext from "../../hooks/useOverflowContext";

export default function SubscriptionPlans() {
    const { dispatch:overflowDispatch } = useOverflowContext();

    const location = useLocation();
    const prevRoute = location.state?.currentRoute ?? "/";

    const isSmall = useMediaQuery({ query: "(max-width: 640px)"});

    const { isAccountActivated, remainingDays } = useSubscriptionContext();
    
    const [packagesElement, setPackagesElement] = useState([]);

    // Unfreeze the screen
    useEffect(() => overflowDispatch({ type: 'SET_FREEZE', payload: false }), [overflowDispatch])

    // Creating the packages element
    useEffect(() => {
        setPackagesElement(packages.map((plan, idx) => {
            return (
                <PackageContainer 
                    key={idx}
                    subscriptionTitle={plan.subscriptionTitle}
                    subscriptionPrice={plan.subscriptionPrice}
                    subscriptionDuration={plan.subscriptionDuration}
                    subscriptionDurationText={plan.subscriptionDurationText}
                    subscriptionDescription={plan.subscriptionDescription}
                    prevRoute={prevRoute}
                />
            )
        }))
    }, [prevRoute]);

    // Show how long is the current subscription active
    const [validUntil, setValidUntil] = useState(new Date());
    useEffect(() => {
        function formatDate(date) {
            const day = String(date.getDate()).padStart(2, '0'); 
            const month = String(date.getMonth() + 1).padStart(2, '0'); 
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }

        setValidUntil(() => {
            const date = new Date();
            date.setDate(date.getDate() + (remainingDays - 1));
            return formatDate(date);
        });
    }, [remainingDays])

    return (
        <div className="subscription-plans--page">
            {isSmall ?
                <TitleBack 
                    title="Tutor Packages"
                    to={prevRoute}
                />
                :
                <BackArrow 
                    to={prevRoute}
                    additionalClass="absolute"
                />
            }
            <div className="subscription-plans--container">
                {!isSmall && <h1>Tutor Subscription Packages</h1>}
                {isAccountActivated &&
                    <p className="plan-details--container">
                        {`Your current plan is valid until ${validUntil}. You can extend your subscription by upgrading to one of our plans. The additional time will be added to your current validity.`}
                    </p>
                }
                <div className="packages--container">
                    {packagesElement}
                </div>
            </div>

        </div>
    )
}