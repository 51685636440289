import { useEffect, useState } from "react";
import useDisableNumberInputScroll from "../hooks/useDisableNumberInputScroll";

export default function Pagination({ page, setPage, totalPages, onChangePage = () => {} }) {
    const [isDisabled, setIsDisabled] = useState({
        left: true,
        right: false
    });
    const [pageCopy, setPageCopy] = useState(page); // this is needed because i don't want the user every time he edits the page it goes away, only when he's done

    useDisableNumberInputScroll();

    // Handle the click of the arrow
    function handleArrow(increment) {
        onChangePage();
        if (totalPages !== page && increment) setPage(prev => prev + 1);
        else if (page !== 1 && !increment) setPage(prev => prev - 1);
    }

    // Check when the arrows should be disabled
    useEffect(() => {
        if (page === 1 && page === totalPages) {
            setIsDisabled({
                left: true,
                right: true
            });
        } else if (page === totalPages) {
            setIsDisabled({
                left: false,
                right: true
            });
        } else if (page === 1) {
            setIsDisabled({
                left: true,
                right: false
            });
        } else {
            setIsDisabled({
                left: false,
                right: false
            });
        }
    }, [page, totalPages]);

    // Sync the value of pageCopy to page
    useEffect(() => {
        setPageCopy(page);
    }, [page])

    // Handle manual page change
    function handlePageChange(event) {
        setPageCopy(event.target.value);
    }

    // Either when the user presses enter or exits the input box
    function handlePageFinish(event) {
        if (!event.key || (event.key && event.key === "Enter")) {
            if (pageCopy > totalPages) {
                setPageCopy(totalPages);
                setPage(totalPages);
            } else if (pageCopy < 1) {
                setPageCopy(1);
                setPage(1);
            } else setPage(Number(pageCopy));
        }
    }

    return (
        <div className="pagination--container">
            <span 
                className={`material-symbols-outlined pagination-arrow${isDisabled.left ? " pagination-arrow--disabled" : ""} arrow-left`}
                onClick={() => handleArrow(false)}
            >
                arrow_left
                {/* keyboard_arrow_left */}
            </span>

            <div className="page-input--container">    
                <input 
                    value={pageCopy}
                    className="pagination--input"
                    onChange={handlePageChange}
                    onBlur={handlePageFinish}
                    onKeyDown={handlePageFinish}
                    type="number"
                ></input>
                <p className="pagination--p">of {totalPages}</p>
            </div>

            <span 
                className={`material-symbols-outlined pagination-arrow${isDisabled.right ? " pagination-arrow--disabled" : ""} arrow-right`}
                onClick={() => handleArrow(true)}
            >
                arrow_right
                {/* keyboard_arrow_right */}
            </span>
        </div>
    )
}