import React, { useState, useEffect } from "react";
import "../../styles/styles.css";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import RevealOnScroll from "../../animations/RevealOnScroll";
import ratingHelpers from "../../helpers/ratingHelpers";

export default function TutorBox(props) {
    const { prepareStarsDisplay } = ratingHelpers;

    // I have to do this so I don't have duplicates of languages (use props.languages)
    const { rating, tutor, tutorInfo, description, hourlyRate, profilePictureURL, numOfRatings } = props;

    const [languages, setLanguages] = useState("");
    const [starsDisplay, setStarsDisplay] = useState([]);
    const [ratingElement, setRatingElement] = useState([]);
    let descriptionSliced = "";
    
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isSmallMobile = useMediaQuery({ query: "(max-width: 540px)"});

    if (!isTablet && description.length > 250) {
        descriptionSliced = description.slice(0, 250) + "...";
    } else if (isTablet && description.length > 170) {
        descriptionSliced = description.slice(0, 170) + "...";
    }
    
    const desktop = (
        <RevealOnScroll extraClass="tutor-box--container" once={true}>
            <div className="tutor-box--course">
                <div className="tutor-box--profile">
                    {profilePictureURL ?
                        <div>
                            <img 
                                src={profilePictureURL}
                                alt="Tutor Profile"
                                className="profile-picture profile-picture-img--tutors"
                                style={{ width: "132px", height: "132px" }}
                                loading="lazy"
                            />
                        </div>
                        :
                        <span className="material-symbols-outlined">account_circle</span>
                    }    
                </div>

                {ratingElement}
                
                <Link 
                    className="small-button" 
                    id="book-session--button"
                    to={`/tutor/courses/${tutorInfo.user.id}`} 
                >Book Session</Link>
            </div>

            <div className="tutor-box--tutor">
                <h3 className="light-title larger-size">{tutor}</h3>
                
                <div className="tutor-box--field">
                    <p className="very-small-title">DESCRIPTION:</p>
                    <p className="light-grey regular">{descriptionSliced || description}</p>
                </div>

                <div className="tutor-box--field">
                    <p className="very-small-title">SPEAKS:</p>
                    <p className="light-grey regular">{languages}</p>
                </div>

                <div className="tutor-box--field">
                    <p className="very-small-title">HOURLY RATE:</p>
                    <div className="flex-row light-grey">
                        <p className="regular">USD&nbsp;</p>
                        <p className="bold">{hourlyRate}</p>
                    </div>
                </div>
            </div>
        </RevealOnScroll>
    );

    const mobile = (
        <RevealOnScroll extraClass="tutor-box--container" once={true}>
            <Link 
                to={`/tutor/courses/${tutorInfo.user.id}`} 
                className="tutor-box--container-mobile"
                state={{ 
                    tutorInfo: tutorInfo,
                    tutor: tutor
                }}
            >
                <div className="tutor-box--course">
                    <div className="tutor-box--profile">
                        {profilePictureURL ?
                            <img 
                                src={profilePictureURL}
                                alt="Tutor Profile"
                                className="profile-picture profile-picture-img--tutors"
                                style={{ width: !isSmallMobile ? "132px" : "44.5px", height: !isSmallMobile ? "132px" : "44.5px" }}
                                loading="lazy"
                            />
                            :
                            <span className="material-symbols-outlined">account_circle</span>
                        }   
                    </div>
                </div>

                <div className="tutor-box--tutor">
                    <h3 className="light-title larger-size">{tutor}</h3>
                    
                    <div className="tutor-box--field" id="language-container--tutor-box">
                        <p className="very-small-title">SPEAKS:</p>
                        <p className="light-grey regular">{languages}</p>
                    </div>

                    <div className="tutor-box--field" id="rate-container--tutor-box">
                        <p className="very-small-title">HOURLY RATE:</p>
                        <div className="flex-row light-grey">
                            <p className="regular">USD&nbsp;</p>
                            <p className="bold">{hourlyRate}</p>
                        </div>
                    </div>

                    {!isSmallMobile ? 
                        ratingElement
                        :
                        <div className="info-container-tutor-box--mobile">
                            <div className="flex-row light-grey">
                                <span className="material-symbols-outlined">attach_money</span>   
                                <p className="bold">{hourlyRate}</p>
                            </div>
                            
                            {rating === 0 ?
                                <p>No rating available</p>
                                :
                                <div className="rating-container--mobile">
                                    <span className="material-symbols-outlined filled-star">star</span>   
                                    <p>{Number(rating).toFixed(2)}</p> 
                                    <p className="num-of-ratings--p" style={{ marginLeft: "5px" }}>{`(${numOfRatings})`}</p>
                                </div>
                            }
                        </div>
                    }
                </div>
            </Link>
        </RevealOnScroll>
    );


    useEffect(() => {
        // prepare the language string
        setLanguages(() => props.languages.reduce((languagesString, currLanguage) => languagesString += ", " + currLanguage));

        // prepare the rating
        let { rating } = props;
        setStarsDisplay(prepareStarsDisplay(rating));
    }, [props, prepareStarsDisplay]);

    // Create the rating element
    useEffect(() => {
     setRatingElement(() => {
        return (
            <div className="tutor-rating-info--container">
                <div className={`tutor-rating--container${rating === 0 ? "" : " rating-shown"}`}>
                    {rating === 0 ? <p style={{ marginBottom: "23px" }}>No rating available</p> : <p>{Number(rating).toFixed(2)}</p>}
                    {rating !== 0 && 
                        <div className="stars--container">
                            {starsDisplay}
                        </div>
                    }
                </div>
                {rating !== 0 && <p className="num-of-ratings--p">{`Based on ${numOfRatings} review${numOfRatings !== 1 ? "s" : ""}`}</p>}
            </div>
        )
     })
    }, [rating, starsDisplay, numOfRatings])

    return (
        isMobile ? mobile : desktop
    )
}