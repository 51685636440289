import { useState } from "react";
import Notification from "../Notifications/Notification";
import ShowMore from "../ShowMore";
import { useMediaQuery } from "react-responsive";
import BannedUserMoreInfo from "./BannedUserMoreInfo";
import toastHelpers from "../../helpers/toastHelper";
import apiPrivate from "../../apiPrivate.js";
import useOverflowContext from "../../hooks/useOverflowContext.js";

export default function BannedUserBox({ user, activeDropdown, setActiveDropdown, idx, setBannedUsers }) {
    const { first_name, last_name, email, profile } = user;
    const { phone_number } = profile;
    const { toastError } = toastHelpers;
    
    const { dispatch:overflowDispatch } = useOverflowContext();
    
    const isMedium = useMediaQuery({ query: "(max-width: 700px" });
    const showMoreFunctions = [
        {
            title: "More Info",
            function: handleUserInfo
        },
        {
            title: "Unblock User",
            function: handleUnbanFunction
        }
    ]; // object to be sent to show more

    const [showUserInfo, setShowUserInfo] = useState(false); 

    const name = `${first_name} ${last_name}`;

    const [confirmUnban, setConfirmUnban] = useState(false);
    const profilePictureURL = user.profile_picture;

    const handleUnban = async () => {
        try {
            await apiPrivate.post("/tutor/unbanUser", 
                {
                    user_id: user.id,  
                }
            );
            overflowDispatch({ type: "SET_FREEZE", payload: false });
            setConfirmUnban(false);
            setBannedUsers(prev => prev.filter(bannedUser => user.id !== bannedUser.id))
        } catch (error) {
            toastError("There was a problem unblocking the user. Please try again.");
        }
    }
    
    async function handleConfirmUnban() {
        await handleUnban();
    }
    
    function handleCancelUnban() {
        overflowDispatch({ type: "SET_FREEZE", payload: false });
        setConfirmUnban(false);
    }

    function handleUnbanFunction() {
        overflowDispatch({ type: "SET_FREEZE", payload: true });
        setConfirmUnban(true);
    }
    
    function closeMoreInfo() {
        overflowDispatch({ type: "SET_FREEZE", payload: false });
        setShowUserInfo(false);
    }
    
    function handleUserInfo() {
        overflowDispatch({ type: "SET_FREEZE", payload: true });
        setShowUserInfo(true);
    }

    return (
        <div className="banned-user-box--container">
            {confirmUnban &&
                <Notification 
                    handleConfirm={handleConfirmUnban}
                    handleCancel={handleCancelUnban}
                    title={"Unblock Request"}
                    content={`Are you sure you want to unblock ${name}?`}
                    doneButton={false}
                    isWarning={true}
                />
            }

            {showUserInfo &&
                <BannedUserMoreInfo 
                    name={name}
                    email={email}
                    phone_number={phone_number}
                    profilePictureURL={profilePictureURL}
                    closeMoreInfo={closeMoreInfo}
                />
            }

            {profilePictureURL ?
                <img 
                    src={profilePictureURL}
                    alt="Tutor Profile"
                    className="profile-picture profile-img--banned-user"
                    loading="lazy"
                />
                :
                <span className="material-symbols-outlined profile-ban">account_circle</span>
            }   

            <div className="banned-user-box--info">
                <h3 className="banned-user-box--h3">{name}</h3>
                
                {
                    !isMedium ? 
                    <div className="banned-user--buttons">
                        <button className="more-info-button" onClick={handleUserInfo}>More Info</button>
                        <button className="unban-button" onClick={handleUnbanFunction}>Unblock</button>
                    </div>
                    :
                    <ShowMore 
                        activeDropdown={activeDropdown}
                        setActiveDropdown={setActiveDropdown}
                        idx={idx}
                        showMoreFunctions={showMoreFunctions}
                    />
                }
            </div>
        </div>
    )
}