import { useEffect, useState } from 'react';
import BookingNotification from '../Notifications/BookingNotification';
import useOverflowContext from '../../hooks/useOverflowContext';
import mappings from '../../data/daysMappings';
import Notification from '../Notifications/Notification';
import toastHelpers from '../../helpers/toastHelper';
import apiPrivate from '../../apiPrivate.js';
import { useNavigate } from 'react-router-dom';
import BannedUserMoreInfo from '../Settings/BannedUserMoreInfo.js';
import useLoaderContext from '../../hooks/useLoaderContext.js';
import { useMediaQuery } from 'react-responsive';
import useBookingsContext from '../../hooks/useBookingsContext.js';
import usePendingsContext from '../../hooks/usePendingsContext.js';

export default function BookingInfoBox(
    { name, courseDetails, date, bookingId, 
        isTutor, setBookings, isPending, 
        isTutorBookings, userId, setDate, hourlyRate, setMessage,
        showMoreInfo, setShowMoreInfo,
        profilePictureURL, phone_number, email, setRefreshDates, refreshDates
     }
) {
    const { days, months } = mappings;
    const { toastError, toastSuccess } = toastHelpers;
    
    const [bookingNotification, setBookingNotification] = useState({ show: false });
    const [banNotification, setBanNotification] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const newDate = new Date(date);

    const [showSingleInfo, setShowSingleInfo] = useState(false);
   
    const { dispatch:overflowDispatch } = useOverflowContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    const { dispatch:bookingDispatch } = useBookingsContext();
    const { dispatch:pendingDispatch } = usePendingsContext();

    const isMobile = useMediaQuery({ query: "(max-width: 550px)"});

    // Create an individual copy for each box that is closed when a click happens outside the box
    useEffect(() => {
        if (!showMoreInfo) {
            setShowSingleInfo(false);
            setShowMoreInfo(true);
        }
    }, [showMoreInfo, setShowMoreInfo])

    // Handle the canceling of the session
    function handleCancel(event) {
        event.stopPropagation();
        setBookingNotification({ show: true });
        setIsCancel(true);
        overflowDispatch({ type: 'SET_FREEZE', payload: true });
    }

    // Handle the approval of the session
    function handleAccept(event) {
        event.stopPropagation();
        setBookingNotification({ show: true });
        setIsCancel(false);
        overflowDispatch({ type: 'SET_FREEZE', payload: true });
    }

    // Handle the blocking of a user
    function handleBan(event) {
        event.stopPropagation();
        setBanNotification(true);
    }

    function handleCancelBan(event) {
        event.stopPropagation();
        setBanNotification(false);
    }

    const handleConfirmBan = async () => {
        try {
            loadingDispatch({ type: 'LOAD' });
            await apiPrivate.post("/tutor/banUser", 
                {
                    user_id: userId,  
                }
            );
            setBanNotification(false);
            setRefreshDates(true);
            toastSuccess("User successfully banned.");
            
            pendingDispatch({ type: 'DELETE_BLOCKED_USER', payload: userId });
            bookingDispatch({ type: 'DELETE_BLOCKED_USER', payload: userId });
            loadingDispatch({ type: 'NO_LOAD' });
        } catch (error) {
            loadingDispatch({ type: 'NO_LOAD' });
            toastError("There was an error banning the user. Please try again.");
        }
    }

    // Change the state of the pop up for more info
    function handleShowMoreInfo(event) {
        event.stopPropagation();
        setShowSingleInfo(prev => !prev)
    } 

    const navigate = useNavigate();
    async function handleTutorRedirect(event) {
        event.stopPropagation();
        navigate(`/tutor/courses/${userId}`);
    }
    
    // Show the user info for the tutor
    const [showUserInfo, setShowUserInfo] = useState(false);
    function handleShowUser(event) {
        event.stopPropagation();
        setShowUserInfo(true);
    }

    return (
        <div 
            className="booking-info-box--container booking-info-box--container-height-pending" 
            style={{ paddingTop: isTutorBookings ? "20px" : "15px", paddigBottom: isTutorBookings ? "20px" : "15px" }}
        >
            {bookingNotification.show && 
            <BookingNotification 
                setBookingNotification={setBookingNotification}
                course={courseDetails.name}
                day={date}
                hour={new Date(date).getHours()}
                isBooking={false}
                student={name}
                isTutor={isTutor}
                bookingId={bookingId}
                setBookings={setBookings}
                isPending={isPending}
                isTutorBookings={isTutorBookings}
                courseId={courseDetails.id}
                userId={userId}
                setDate={setDate}
                setMessage={setMessage}
                isCancel={isCancel}
                setRefreshDates={setRefreshDates}
                refreshDates={refreshDates}
            />}

            {banNotification &&
                <Notification 
                    handleConfirm={handleConfirmBan}
                    handleCancel={handleCancelBan}
                    title={"Block Request"}
                    content={`Are you sure you want to block ${name}?`}
                    doneButton={false}
                    isWarning={true}
                />
            }

            {showUserInfo &&
                <BannedUserMoreInfo 
                    name={name} 
                    profilePictureURL={profilePictureURL}
                    phone_number={phone_number}
                    email={email}
                    closeMoreInfo={() => setShowUserInfo(false)}
                />
            }

            <div className="booking-info-box--info" style={{ rowGap: isTutorBookings ? "0" : "10px" }}>
                <div className="booking-info-box--title">
                    <div className="pending-ban--container">
                        <h3>{name}</h3>
                        <span className="material-symbols-outlined more-horiz-icon" onClick={handleShowMoreInfo}>
                            more_horiz
                        </span>

                        <div className="show-more-booking--container" style={{ display: showSingleInfo ? "flex" : "none" }}>
                            {isTutorBookings && <button onClick={handleBan}>Block User</button>}
                            {isTutorBookings && isPending && <button onClick={handleAccept}>Accept Request</button>}  
                            <button onClick={handleCancel}>Cancel Request</button>  
                            {isTutorBookings && <button onClick={handleShowUser}>Show Info</button>}  
                            {!isTutorBookings && <button onClick={handleTutorRedirect}>Show Info</button>}  
                        </div>
                    </div>
                </div>
                
                <div className="booking-info-box--section">
                    <p>
                        <span className="booking-info-box--section-title">Course:</span>
                        <span>{`${courseDetails.name}${isMobile ? ", " : ""}`}</span>
                        
                    </p>
                    <div className="booking-info-box--mobile">
                        <p>{`${newDate.getHours()}:00`}</p>
                        {isTutorBookings &&
                            <p>
                                <span>{`|`}</span>
                                <span>{`${hourlyRate || 23}$`}</span>
                            </p>
                        }
                    </div>
                </div>
            </div>

            <div className="date-time-booking-box">
                <div className="date-time-booking--subcontainer">
                    <span className="material-symbols-outlined">
                        today
                    </span>
                    <p>{`${days[newDate.getDay()].substring(0,3)} ${newDate.getDate()} ${months[newDate.getMonth()].substring(0,3)}`}</p>
                </div>
                <div className="date-time-booking--subcontainer">
                    <span className="material-symbols-outlined">
                        schedule
                    </span>
                    <p>{`${newDate.getHours()}:00`}</p>
                </div>
                {!isTutorBookings && 
                <div className="date-time-booking--subcontainer">
                    <span className="material-symbols-outlined">
                        attach_money
                    </span>
                    <p>{hourlyRate}</p>
                </div>}
            </div>
        </div>
    )
}