import { useState, useEffect, memo } from "react";
import api from "../../api.js";
import useErrorContext from "../../hooks/useErrorContext.js";

export const SmallSearch = memo(({ isSettings, setCourses, isCourses, setLanguages, isMobile }) => {
    const [searchCourse, setSearchCourse] = useState("");
    const [searchLanguage, setSearchLanguage] = useState("");

    const { dispatch:errorDispatch } = useErrorContext();

    useEffect(() => {
        // Get all courses
        const fetchCourses = async () => {
            try {
                const params = { search_entry: searchCourse };
                const res = await api.get('/getAllCourses/', { params });
                setCourses(res.data);
            } catch (error) {
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
            }
        }

        // Get all languages
        const fetchLanguages = async () => {
            try {
                const params = { search_entry: searchLanguage };
                const res = await api.get('/getLanguages/', { params });
                setLanguages(res.data);
            } catch (error) {
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
            }
        }


        if (isCourses) fetchCourses();
        else fetchLanguages();
    }, [searchCourse, searchLanguage, isCourses, setCourses, setLanguages, errorDispatch]);

    // Handle the search of the courses
    function handleSearch(event) {
        const { value } = event.target;

        if (isCourses) setSearchCourse(value);
        else setSearchLanguage(value);
    }

    // Handle the clearing of the field
    function handleClear() {
        if (isCourses) {
            setSearchCourse("");
        } else {
            setSearchLanguage("");
        }
    }
    
    return (
        <div id="search-bar-container--form" className={`sticky${(isSettings || isMobile) ? " settings-white-bg" : ""}`}>
            <input 
                id="search-course--form" 
                placeholder={`Search ${isCourses ? "courses" : "languages"}`} 
                onChange={handleSearch} 
                value={isCourses ? searchCourse: searchLanguage} 
                className={(isSettings || isMobile) ? "settings-white-bg" : ""}
            />
            {((isCourses && searchCourse !== "") || (!isCourses && searchLanguage !== "")) && 
                <span 
                    className="material-symbols-outlined" 
                    id="close-icon--form"
                    onClick={handleClear}
                >close</span>
            }
            <span className="material-symbols-outlined" id="search-icon--form">search</span>
        </div>
    )
})