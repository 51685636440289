import React from 'react';
import { Link } from 'react-router-dom';

export default function PrivacyPolicy() {
    return (
        <div className="privacy-policy--container">
            <iframe
                title="Privacy Policy"
                src={require('../../htmls/Studytron Privacy Policy.htm')}
                className="privacy-policy--html"
            />

            <Link to="/sign-up" className="small-button privacy-policy--button">Back to Sign Up</Link>
        </div>
    );
}
