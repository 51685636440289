import { useRef } from "react";
import PageIntro from "./PageIntro";
import { Footer } from "./Footer";
import SmallIntro from "./SmallIntro";
import { Typewriter } from 'react-simple-typewriter'
import { useInView } from "framer-motion";

export default function About() {
    const titleRef1 = useRef(null);
    const titleRef2 = useRef(null);

    const whyUsRef1 = useRef(null);
    const whyUsRef2 = useRef(null);

    const isTitleRef1 = useInView(titleRef1, { threshold: 0.15 });
    const isTitleRef2 = useInView(titleRef2, { threshold: 0.15 });
    
    return (
        <div className="flex-column">
            <PageIntro 
                active="about"
                title="About Us"
                description="Who we are and what we do."
                background="background-image-about"
            />

            <div id="about--container">
                <SmallIntro 
                    smallTitle={"About us"}
                    title={"We connect students and tutors."}
                    description={[
                        "At Studytron, ",
                        "we created this platform to empower students by giving them the freedom to choose their own tutors based on their specific courses and academic needs. We believe in personalized learning, where each student can find a tutor who fits their unique learning style and subject requirements.",
                        "Our platform also allows individuals who are confident in their expertise to become tutors and share their knowledge with others. Whether you're a student seeking guidance or someone who wants to help others succeed, we are here to support your educational journey."
                    ]}
                />

                <div id="about-image"></div>

                <div id="why-us--container">
                    <div>
                        {isTitleRef1 && <p className="small-title">
                            <Typewriter
                                words={['Why Choose Us?']}
                                loop={1}
                                cursor
                                cursorStyle='|'
                                typeSpeed={130}
                            />
                        </p>}
                        {/* <p className="small-title">Why us</p> */}
                        <h3 ref={titleRef1} className="h3-title">Find the right tutor, on your terms.</h3>
                    </div>

                    <div ref={whyUsRef1}>
                        <div className="why-us--text">
                            <span className="material-symbols-outlined down-arrow">arrow_insert</span>
                            <div>
                                <h6 className="why-us-subtitle">Expert help</h6>
                                <p className="light-grey">Easily find tutors who specialize in the courses you’re taking, with options to sort by rating and price to ensure you connect with the best fit for your learning style and budget.</p>
                            </div>
                        </div>
                        
                        <div className="why-us--text">
                            <span className="material-symbols-outlined down-arrow">arrow_insert</span>
                            <div>
                                <h6 className="why-us-subtitle">Flexible Scheduling</h6>
                                <p className="light-grey">Enjoy the flexibility of scheduling sessions down to the exact hour, allowing you to seamlessly integrate tutoring into your busy schedule without any conflicts.</p>
                            </div>
                        </div>
                    </div>

                    <div>
                        {isTitleRef2 && <p className="small-title">
                                <Typewriter
                                    words={['Why Choose Us?']}
                                    loop={1}
                                    cursor
                                    cursorStyle='|'
                                    typeSpeed={130}
                                />
                        </p>}
                        {/* <p className="small-title">Why us</p> */}
                        <h3 ref={titleRef2} className="h3-title">Teach what you love, on your own schedule.</h3>
                    </div>

                    <div ref={whyUsRef2}>
                        <div className="why-us--text">
                            <span className="material-symbols-outlined down-arrow">arrow_insert</span>
                            <div>
                                <h6 className="why-us-subtitle">Teach With Impact</h6>
                                <p className="light-grey">Share your expertise and passion with students who are eager to learn, creating an engaging and rewarding teaching experience that can positively impact their academic journey.</p>
                            </div>
                        </div>
                        
                        <div className="why-us--text">
                            <span className="material-symbols-outlined down-arrow">arrow_insert</span>
                            <div>
                                <h6 className="why-us-subtitle">Control Your Terms</h6>
                                <p className="light-grey"> Have the freedom to set your own availability and pricing, giving you control over your teaching experience and the opportunity to balance tutoring with your other commitments.</p>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </div>
    )
}