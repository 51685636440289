import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import NetworkStatus from "./components/NetworkStatus";
import ApiCalls from "./apiCalls/ApiCalls";

import { CombinedContextProvider } from './CombinedContextProviders';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'production') {
  console.error = () => {};
}

root.render(
  <React.StrictMode>
    <CombinedContextProvider>
      <App />
      <NetworkStatus />
      <ApiCalls />
    </CombinedContextProvider>  
  </React.StrictMode>
);