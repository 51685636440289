import React from "react";
import "../styles/styles.css";
import { Navbar } from "./Navbar";

export default function PageIntro({ active, background, title, description, hasSearch }) {
    return (
        <div id={hasSearch ? "" : "page-intro--container"}>
            <Navbar 
                active={active} 
                additionalStyle={hasSearch ? { position: "absolute" } : {}}
            />
            <div 
                id="page-intro" 
                className={background} 
                style={{ 
                    position: hasSearch ? "static" : "absolute",
                    minHeight: hasSearch ? "450px" : undefined 
                }}
            >
                <h1>{title}</h1>
                <h6>{description}</h6>
            </div>
        </div>
    )
}