import { useState, useEffect, useRef } from "react";
import PageIntro from "../PageIntro";
import CoursesSearchBar from "./CoursesSearchBar";
import Box from "../Box";
import { Footer } from "../Footer";
import { useLocation, Link } from "react-router-dom";
import RevealOnScroll from "../../animations/RevealOnScroll";
import Pagination from "../Pagination";

export default function Courses() {
    const { state } = useLocation();

    const currMajor = state ? (state.major ? state.major.name : "") : "";
    const currId = state ? (state.major ? state.major.id : "") : "";

    const [courses, setCourses] = useState([]);
    const [searchCourse, setSearchCourse] = useState("");
    const [coursesElement, setCoursesElement] = useState([]);
    const [major, setMajor] = useState(currMajor);
    const [majorId, setMajorId] = useState(currId);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [isFirstRender, setIsFirstRender] = useState(true);

    const topRef = useRef(null);
    
    // Display the courses
    useEffect(() => {
        setCoursesElement(() => {
            return courses.map((course, id) => {
                return (
                    <RevealOnScroll once={true} key={id}>
                        <Link to="/tutors" state={{ course }} key={id}>
                            <Box 
                                key={id}
                                name={course.name}
                                abbreviation={course.code}
                                elementName={`Tutor${course.num_tutors !== 1 ? "s" : ""}`}
                                elements={course.num_tutors}
                                extraClass=" box-courses"
                            />
                        </Link>
                    </RevealOnScroll>
                )
            })
        });
    }, [courses])

    return (
        <div className="flex-column">
            <div id="page-intro--container">
                <PageIntro 
                    active="courses"
                    title="Courses"
                    description="Find your ideal tutor for each course."
                    background="background-image-courses"
                    hasSearch={true}
                />
            
                <CoursesSearchBar 
                    major={major}
                    majorId={majorId}
                    courses={courses}
                    searchCourse={searchCourse}
                    setSearchCourse={setSearchCourse}
                    setCourses={setCourses}
                    setMajorId={setMajorId}
                    setMajor={setMajor}
                    page={page}
                    totalPages={totalPages}
                    setTotalPages={setTotalPages}
                    topRef={topRef}
                    isFirstRender={isFirstRender}
                    setIsFirstRender={setIsFirstRender}
                />
            </div>
            <div id="courses--container">
                <div id="courses-available--container" ref={topRef}>
                    {coursesElement}
                </div>

                {coursesElement.length === 0 ? <p>There is no course available for this major yet.</p> : ""}
            </div>

            {courses.length !== 0 &&
                <Pagination 
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                    onChangePage={() => setIsFirstRender(true)}
                />}

            <Footer />
        </div>
    )
}