import React, { useState, useEffect } from "react";
import mappings from '../../data/daysMappings';
import useAuthContext from '../../hooks/useAuthContext';
import useUserInfoContext from '../../hooks/useUserInfoContext';
import { CalendarBookingBox } from './CalendarBookingBox';
import useLoaderContext from "../../hooks/useLoaderContext";
import useErrorContext from "../../hooks/useErrorContext";
import apiPrivate from "../../apiPrivate";

export default function CalendarBooking({ 
    date, setDate, isBooking, addDate, tutorId, isPending, isTutorBookings, setIsTutoringBookings,
    refreshDates, setRefreshDates
}) {
    const { isTutor } = useUserInfoContext();
    const { dispatch:errorDispatch } = useErrorContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    
    const { token } = useAuthContext();

    const { days, months } = mappings;

    const [bookedDays, setBookedDays] = useState(null);
    const [fullyBookedDays, setFullyBookedDays] = useState(null);

    useEffect(() => {
        // Get the booked days of the tutor
        const getBookedDays = async () => {
            try {
                let url;
                if (isBooking) {
                    url = `user/getBookedDays?tutor_id=${tutorId}`;
                } else if (isTutorBookings) {
                    url = "tutor/getBookedDays";
                } else {
                    url = "user/getMyBookedDays";
                }

                const res = await apiPrivate.get(url);

                const { data } = res;
                
                if (isTutorBookings || isBooking) {
                    orderDays(data);
                } else {
                    setBookedDays(data.map(date => new Date(date)));
                    setFullyBookedDays([]);
                }
            } catch (error) {
                loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
            }
        }
        
        const getPendingDays = async () => {
            try {
                const res = await apiPrivate.get(`/${isTutorBookings ? "tutor" : "user"}/getPendingDays`);
                const { data } = res;

                setBookedDays(data.map(date => new Date(date)));
                setFullyBookedDays([]);
            } catch (error) {
                loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response }); 
                
            }
        }

        if ((!isPending && isTutor !== null && token) || (!isPending && refreshDates)) {
            getBookedDays();
            if (refreshDates) setRefreshDates(false);
        };

        if ((isPending && isTutor !== null && token && isTutorBookings !== null) || (isPending && refreshDates)) {
            getPendingDays();
            if (refreshDates) setRefreshDates(false);
        };
    }, [isTutor, token, isTutorBookings, isBooking, tutorId, isPending, loadingDispatch, errorDispatch, refreshDates, setRefreshDates]);

    // Seperate the booked from fully booked days
    function orderDays(data) {
        const booked = [], fullyBooked = [];

        for (const day of data) {
            if (day.fully_booked) fullyBooked.push(new Date(day.date));
            else booked.push(new Date(day.date));
        }

        setBookedDays(booked);
        setFullyBookedDays(fullyBooked);
    }

    return (
        <div id={`${isBooking ? "" : "all-bookings--calendar"}`}>
            {isTutor && !isBooking &&
            <div className="all-bookings-tutor--buttons">
                <button 
                    className={`all-bookings-tutor--button${isTutorBookings ? " all-bookings-tutor--button--selected" : ""}`}
                    onClick={() => setIsTutoringBookings(true)}
                >Your Students</button>
                <button 
                    className={`all-bookings-tutor--button${!isTutorBookings ? " all-bookings-tutor--button--selected" : ""}`}
                    onClick={() => setIsTutoringBookings(false)}
                >Your Tutors</button>
            </div>}
            
            <CalendarBookingBox 
                date={date}
                bookedDays={bookedDays}
                fullyBookedDays={fullyBookedDays}
                setDate={setDate}
            />

            {addDate && date && 
                <div className="all-bookings--current-day">
                    <span className="material-symbols-outlined" id="date-icon">
                        calendar_month
                    </span>

                    <h5>{`${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`}</h5>
                </div>
            }
        </div>
    )
}