const packages = [
    {
        subscriptionTitle: "Monthly Plan",
        subscriptionPrice: 29,
        subscriptionDuration: 1,
        subscriptionDurationText: "/ month",
        subscriptionDescription: "Ideal for tutors with short-term goals or those who prefer a month-to-month commitment."
    },
    {
        subscriptionTitle: "Semester Plan",
        subscriptionPrice: 99,
        subscriptionDuration: 4,
        subscriptionDurationText: "/ 4 months",
        subscriptionDescription: "Ideal for tutors looking for a longer-term commitment and better value without worrying about frequent renewals."
    }
]

export default packages;