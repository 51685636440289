import toastHelpers from "./toastHelper";
import apiPrivate from "../apiPrivate";
import imageCompression from "browser-image-compression";

const { toastError, toastSuccess } = toastHelpers;
const errorMessage = "Something went wrong. Please try again.";
const successMessage = "Changes saved successfully!";

// Change name
const changeName = async (originalForm, formData, userInfoDispatch, setOriginalForm) => {
    const params = {};

    if (originalForm.first_name !== formData.first_name) {
        params.first_name = formData.first_name;
    }

    if (originalForm.last_name !== formData.last_name) {
        params.last_name = formData.last_name;
    }

    try {
        await apiPrivate.put(`/user/changeName`, {}, { params });

        const updatedInfo = {
            first_name: formData.first_name,
            last_name: formData.last_name
        };

        userInfoDispatch({ type: 'EDIT_USERINFO', payload: updatedInfo });
        setOriginalForm(updatedInfo);
        toastSuccess(successMessage);
    } catch (error) {
        toastError(errorMessage);
    }
}

// Change password
const changePassword = async (formData, setShowSettings, logout) => {
    try {
        await apiPrivate.put(`/user/changePassword`, {
            "old_password": formData.old_password,
            "new_password": formData.password,
            "confirm_password": formData.confirm_password                
        });

        logout();
        setShowSettings(false);
        toastSuccess(successMessage);
    } catch (error) {
        toastError(errorMessage);
    }
}

// Change phone number
const changePhoneNumber = async (userInfo, fileURL, formData, userInfoDispatch, setOriginalForm) => {
    try {
        await apiPrivate.put(`/user/changePhoneNumber`, {
            "phone_number": formData.profile.phone_number          
        });

        const updatedInfo = {
            ...userInfo.profile,
            phone_number: formData.profile.phone_number
        };

        userInfoDispatch({ type: 'EDIT_USERINFO', payload: { profile: { ...updatedInfo } } });
        setOriginalForm(updatedInfo);
        toastSuccess(successMessage);
    } catch (error) {
        if (error.response.data.error) {
            toastError(error.response.data.error);
        } else {
            toastError(errorMessage);
        }
    }
}

// Change the profile picture
const uploadProfilePicture = async (userInfoDispatch, fileUpload, fileURL, setOriginalPicture, setFileURL, setFileUpload) => {
    try {
        const formDataFile = new FormData();
        formDataFile.append('file', fileUpload);
        
        const response = await apiPrivate.post('/user/uploadProfilePicture', formDataFile);

        userInfoDispatch({ type: 'EDIT_USERINFO', payload: { profile_picture: response.data.file } });

        setOriginalPicture(fileURL)
        setFileURL(null);
        setFileUpload(null);
        toastSuccess(successMessage);
    } catch (error) {
        toastError(errorMessage);
    }
}

// Remove profile picture
const deleteProfilePicture = async (userInfoDispatch, setOriginalPicture, setFileURL, setFileUpload) => {
    try {
        await apiPrivate.delete('/user/deleteProfilePicture');

        userInfoDispatch({ type: 'EDIT_USERINFO', payload: { profile_picture: null }});

        setOriginalPicture(null);
        setFileURL(""); 
        setFileUpload(null);
        toastSuccess(successMessage);
    } catch (error) {
        toastError(errorMessage);
    }
}

// Crop and Compress the image
async function cropImage(cropperRef, setFileUpload, setFileURL) {
    const cropper = cropperRef.current?.cropper;
  
    if (cropper) {
      try {
        // Wrap `toBlob` in a Promise for await usage
        const blob = await new Promise((resolve, reject) => {
          cropper.getCroppedCanvas({
            width: cropper.canvasData.width, // Maintain original dimensions
            height: cropper.canvasData.height,
          }).toBlob(
            (blob) => {
              if (blob) {
                resolve(blob); // Resolve with the blob
              } else {
                reject(new Error("Failed to create Blob"));
              }
            },
            "image/jpeg", // Output image format
            1 // Compression quality (100%)
          );
        });
        
        return blob;
      } catch (error) {
        console.error("Error during cropping and upload:", error);
      }
    }
};

async function compressImage(file) {
    // compress the profile picture if there is one
    const options = {
        maxSizeMB: 0.4,
        maxWidthOrHeight: undefined,
        useWebWorker: true,
    }
    
    const compressedFile = await imageCompression(file, options);
    return new File([compressedFile], file.name, {
        type: file.type,
        lastModified: Date.now(),
    });
}

const settingsHelpers = {
    changeName,
    changePassword,
    changePhoneNumber,
    uploadProfilePicture,
    deleteProfilePicture,
    cropImage,
    compressImage
}

export default settingsHelpers;