import React from "react";
import "../../styles/home.css";

export default function HomeIntro() {
    return (
        <div id="homeintro--container" className="flex-column">
            <div id="homeintro-text">
                <p>
                    <span>Need a tutor at </span>
                    <span className="bold-green">LAU</span>
                    <span>?</span>
                </p>
                <h1>
                    Welcome to Studytron!
                </h1>
            </div>
        </div>
    )
}