import "../styles/contact.css";
import { Footer } from "../components/Footer.js";
import PageIntro from "../components/PageIntro";
import "../styles/styles.css";
import { useState } from "react";
import toastHelpers from "../helpers/toastHelper.js";
import apiPrivate from "../apiPrivate.js";
import SignInPopUp from "./SignInUp/SignInPopUp.js";
import { useNavigate } from "react-router-dom";
import useOverflowContext from "../hooks/useOverflowContext.js";
import useAuthContext from "../hooks/useAuthContext.js";

function Contact() {
    const [formData, setFormData] = useState({
        subject: "",
        message: ""
    });
    
    const { toastError, toastSuccess } = toastHelpers;
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { dispatch:overflowDispatch } = useOverflowContext();
    const { token } = useAuthContext();

    // Handle form change
    function handleChange(event) {
        const { name, value } = event.target;
        setError(null);

        setFormData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    // Sent the Contact API
    const contactUs = async () => {
        try {
            await apiPrivate.post('/user/contactUs',{
                ...formData
            });
            setFormData({
                subject: "",
                message: ""
            })
            toastSuccess("Message Sent. Thank you!");
        } catch (error) {
            toastError(error.response.data);
        }
    }

    // Handle the submit of the form
    async function handleSubmit(event) {
        event.preventDefault();
        const error = {};
        const emptyFieldsMessage = "All fields must be filled.";
        
        if (formData.subject === "") {
            error['subject'] = emptyFieldsMessage;
        }

        if (formData.message === "") {
            error['message'] = emptyFieldsMessage;
        }

        setError(error);

        if (Object.keys(error).length === 0) contactUs();
    }

    // Close the sign in pop up
    function handleClosePopup() {
        overflowDispatch({ type: 'SET_FREEZE', payload: false });
        navigate("/");
    }

    return (
        !token ?
        <SignInPopUp 
            purpose="send us your inquiries"
            onClose={handleClosePopup}
        />
        :
        <div className="flex-column"> 
            <PageIntro 
                active={"contact"} 
                title={"Contact Us"} 
                description={"Your inquiries are welcome here."} 
                background={"background-image-contact"}
            />
            
            <div id="contact--container">
            <span className="light-grey" style={{ marginBottom: "20px", textAlign: "center" }}>Send us a message below or email us at: <a className="mail" href="mailto:studytron01@gmail.com">studytron01@gmail.com</a></span>

                <div className="form-container">
                    <form>
                        <div className="form-row">
                            <label htmlFor="subject" className="label">Subject <span className="red">*</span></label>
                            <input 
                                name="subject" 
                                type="text" 
                                id="message" 
                                placeholder="Subject" 
                                required 
                                className={`subject-container${error && error.subject ? " error-borders" : ""}`}
                                value={formData.subject}
                                onChange={handleChange}
                            ></input>
                        </div>
                        <div className="form-row">
                            <label htmlFor="message" className="label">Message <span className="red">*</span></label>
                            <textarea 
                                name="message" 
                                id="message" 
                                placeholder="Message" 
                                required
                                className={`${error && error.message ? "error-borders" : ""}`}
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        {error && <p className="error-p" style={{ marginTop: "-20px", marginBottom: "30px" }}>{error[Object.keys(error)[0]]}</p>}

                        <button type="submit" className="submit-button" onClick={handleSubmit}>Submit</button>
                    </form>
                </div>
            </div>
            <Footer />
         </div>
    );
}

export default Contact;
