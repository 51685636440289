import { memo } from "react";
import { Link } from "react-router-dom";
import useErrorContext from "../../hooks/useErrorContext";

export const Error = memo((props) => {
    const { errorTitle, errorMessage, dispatch:errorDispatch, is404 } = useErrorContext();
    
    function disableError() {
        errorDispatch({ type: 'NO_ERROR' });
    }

    const reload = () => {
        window.location.reload();
    }

    return (
        <div id="error--container">
            <div className="error-title--container">
                <h2>{(props && props.errorTitle) || errorTitle || "An Error Occurred"}</h2>
                <span className="material-symbols-outlined error-icon">feedback</span>
            </div>
            
            <hr></hr>

            <div className="error-description--container">
                <p>{(props && props.errorMessage) || errorMessage || "An error occured. Please try again."}</p>
                <div className="error-buttons--container">
                    <Link to="/" className="small-button" onClick={disableError}>Back to Home</Link>
                    {!(props?.is404 !== undefined ? props.is404 : is404) && (
                        <Link onClick={reload} className="try-again--error">Try again</Link>
                    )}
                </div>
            </div>
        </div>
    )
})