import mappings from "../../data/daysMappings";
import 'react-calendar/dist/Calendar.css';
import { memo, useEffect, useMemo, useState } from 'react';
import useAuthContext from '../../hooks/useAuthContext';
import useLoaderContext from '../../hooks/useLoaderContext';
import { EditHoursCalendar } from './EditHoursCalendar';
import dateHelpers from '../../helpers/dateHelpers';
import Notification from "../Notifications/Notification";
import toastHelpers from "../../helpers/toastHelper";
import useErrorContext from "../../hooks/useErrorContext.js";
import apiPrivate from "../../apiPrivate.js";
import useSubscriptionContext from "../../hooks/useSubscriptionContext.js";
import { Link } from "react-router-dom";

export const EditHours = memo(() => {
    const { days, months } = mappings;
    const { areDatesEqual } = dateHelpers;
    const { toastSuccess, toastError } = toastHelpers;

    const [slots, setSlots] = useState([]); // this is the slot of the tutor with the date and time

    const [date, setDate] = useState(null);
    const availableHours = useMemo(() => [7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22], []);
    
    const [availableHoursElements, setAvailableHoursElements] = useState([]); // this is to render the hours checkbox
    const [isDisabled, setIsDisabled] = useState(true); // disable the showing of the edit hours button
    const [time, setTime] = useState(""); 
    const [hoursSelectedArr, setHoursSelectedArr] = useState([]); // the hours that are selected
    const [originalTutorHours, setOriginalTutorHours] = useState(null); // this is to be able to compare which hours where added which where removed
    
    const [bookedDays, setBookedDays] = useState(null);

    const { dispatch:errorDispatch } = useErrorContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    const { isAccountActivated } = useSubscriptionContext();
    
    const { token } = useAuthContext();

    const errorMessage = "An error occurred. Please try again";
    const successMessage = "Changes saved successfully!";

    // Add slot for tutor
    const addSlot = async (hour, day, month) => {
        try {
            await apiPrivate.post('/tutor/createSlot', {
                hour,
                day,
                month
            });

            // add this day to the booked days array to color the calendar
            if (bookedDays && !bookedDays.includes(new Date(date))) {
                setBookedDays(prev => {
                    const newArr = [...prev];
                    newArr.push(new Date(date));
                    return newArr;
                })
            }
            toastSuccess(successMessage);
        } catch (error) {
            toastError(errorMessage);
        }
    }

    // Check if the session is booked already
    const checkBookingStatus = async (booking_id) => {
        try {
            const data = await apiPrivate.get(`/tutor/checkBookingStatus`, {
                params: {
                    booking_id
                }
            });

            return data.data;
        } catch (error) {
            loadingDispatch({ type: 'NO_LOAD' });
            errorDispatch({ type: 'ERROR', isNetworkError: !error.response });   
             
        }
    }

    // Delete slot for tutor
    const deleteSlot = async (booking_id) => {
        try {
            await apiPrivate.delete(`/tutor/deleteSlot`, {
                params: { 
                    booking_id 
                }
            });

            // delete the day if no hours are left
            if (hoursSelectedArr.length === 0) {
                setBookedDays(prev => prev.filter(day => !areDatesEqual(day, date)))
            }
            toastSuccess(successMessage);
        } catch (error) {
            toastError(errorMessage);
        }
    }

    
    // Fetch all the previously gotten slots
    useEffect(() => {
        // Get the slots for tutor
        const getSlots = async () => {
            try {
                loadingDispatch({ type: 'LOAD' });
                const { data } = await apiPrivate.get('/tutor/getHours');
                setSlots(data);
                loadingDispatch({ type: 'NO_LOAD' });
            } catch (error) {
                loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
            }
        }

        if (token && !date) {
            if (!date) {
                getSlots()} // after the reload or after edit hours is submitted
        }
    }, [token, date, loadingDispatch, errorDispatch]);
    
    // Get booked days
    useEffect(() => {
        // Get the available days of the tutor
        const getBookedDays = async () => {
            try {
                loadingDispatch({ type: 'LOAD' });
                const { data } = await apiPrivate.get('/tutor/getSlottedDays');
               
                setBookedDays(data.map(booking => new Date(booking)));
                loadingDispatch({ type: 'NO_LOAD' });
            } catch (error) {
                loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
            }
        }

        if (token) getBookedDays();
    }, [loadingDispatch, errorDispatch, token])

    // Creating the hours options
    useEffect(() => {
        if (date) {
            const month = Number(date.getMonth() + 1);
            const day = date.getDate() + "";
            let days = [];
            
            // find the days in the month
            for (let i = 0; i < slots.length; i++) {
                if (slots[i].month === month) days = slots[i].days;
            }
            const hours = days[day] || [];
            
            // get the original slots
            setOriginalTutorHours(hours.map((slot) => {
                const currDate = new Date(slot.start_time);
                const hour = currDate.getHours();

                return {
                    id: slot.id,
                    hour
                }
            }));
            
            // get the hours already prepared for that day
            setHoursSelectedArr(hours.map((slot) => {
                const currDate = new Date(slot.start_time);
                const hour = currDate.getHours();

                return hour;
            }))
        }
    }, [date, slots]);

    useEffect(() => {
        // Handle the hour checkbox
        function handleHourCheckbox(event) {
            const hourClicked = Number(event.target.value);
     
            if (hoursSelectedArr && hoursSelectedArr.includes(hourClicked)) {
                // remove the hour from the array
                setHoursSelectedArr(prev => prev.filter(hour => hour !== hourClicked));
            } else {
                // add the hour to the array
                setHoursSelectedArr(prev => {
                    const prevArr = [...prev];
                    prevArr.push(hourClicked);
                    return prevArr;
                })
            }
        }

        // check the hours previously selected
        setAvailableHoursElements(availableHours.map((hour, idx) => {
            return <div key={idx}>
                        <input type="checkbox" name={hour} value={hour} onChange={handleHourCheckbox} checked={hoursSelectedArr && hoursSelectedArr.includes(hour)} />{`${hour}:00`}
                   </div>
        }))

        // update the time rendering
        setTime(hoursSelectedArr.sort((a, b) => a - b).map((hour, idx) => {
            if (idx === 0) return hour + ":00";

            return ` | ${hour}:00`;
        }));
    }, [hoursSelectedArr, availableHours]);


    // Check when the hour and time are both selected
    useEffect(() => {
        setIsDisabled(!date);
    }, [date]);


    // Handle adding the hour
    const [alreadyBooked, setAlreadyBooked] = useState({
        status: false,
        bookingId: null,
        isBooked: null
    });
    async function editHours() {
        for (let i = 0; i < hoursSelectedArr.length; i++) {
            const hour = hoursSelectedArr[i];
            
            if (!searchHour(hour)) {
                // it's not originally found so it was added
                await addSlot(hoursSelectedArr[i], date.getDate(), date.getMonth() + 1);

                setDate(null);
                setTime("");
                setHoursSelectedArr([]);
            } 
        }

        for (let i = 0; i < originalTutorHours.length; i++) {
            const hour = originalTutorHours[i];
            
            if (hoursSelectedArr && !hoursSelectedArr.includes(hour.hour)) {
                // it's not found in the selected array anymore, so it was deleted
                const status = await checkBookingStatus(hour.id);

                if (status.available) {
                    await deleteSlot(hour.id)
                    
                    setDate(null);
                    setTime("");
                    setHoursSelectedArr([]);
                } else {
                    setAlreadyBooked({
                        status: true,
                        bookingId: hour.id,
                        isBooked: status.booking ? true : false,
                        hour: hour.hour
                    });
                }
            } 
        }

    };
    
    // Check if the original hours contains a specific hour
    function searchHour(hour) {
        for (const hourDetails of originalTutorHours) {
            if (hourDetails.hour === hour) return true;
        }

        return false;
    }

    // Confirming the deletion of a slot knowing the tutor either have pendings or bookings
    async function handleConfirm() {
        await deleteSlot(alreadyBooked.bookingId);
        setAlreadyBooked({
            status: false,
            bookingId: null,
            isBooked: null
        });
    }

    function handleCancel() {
        setAlreadyBooked({
            status: false,
            bookingId: null,
            isBooked: null
        });
    }

    return (
        <div id="available-hours--container--settings">
            {alreadyBooked.status && 
            <Notification 
                title={"Attention!"}
                content={`${alreadyBooked.isBooked ? "This session is already booked." : `You have pending requests on ${months[date.getMonth()]} ${date.getDay()} at ${alreadyBooked.hour}:00.`} Are you sure you want to proceed?`}
                doneButton={false}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                isWarning={true}
            />}

            <h1 className="settings-subtitle">Date and Time</h1>

            {!isAccountActivated ? 
                <p className="account-activation--settings">
                    <span>To set your available hours, please </span>
                    <Link to="/subscription-plans" state={{ currentRoute: "/advanced-settings" }} className="account-activation-link--settings">subscribe</Link>
                    <span> to one of our tutor plans.</span>
                </p>
            :
                <div>
                    <label className="label available-hours--settings">Available Hours</label>

                    <div id="calendar-hour--container">
                        <div id="calendar-hours--container">
                            <EditHoursCalendar 
                                date={date}
                                setDate={setDate}
                                bookedDays={bookedDays}
                            />

                            <div id="hours--container">
                                {availableHoursElements}
                            </div>
                        </div>

                        {(date || "" + time !== "") && 
                            <div className="flex-column" id="date-time">
                                <div className="date-time--container">
                                    <span className="material-symbols-outlined" id="date-icon">
                                        calendar_month
                                    </span>
                                    {date && <p id="date-result">{`${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]}`}</p>}
                                </div>

                                <div className="date-time--container">
                                    <span className="material-symbols-outlined" id="hour-icon">
                                        schedule
                                    </span>
                                    {time !== "" && <p id="hour-result">{time}</p>}
                                </div>

                                <button className={`small-button${isDisabled ? " disabled-button" : ""}`} disabled={isDisabled} onClick={editHours}>Edit Hours</button>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
})