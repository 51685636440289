import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom"
import useAuthContext from "../../hooks/useAuthContext";
import CalendarBooking from "./CalendarBooking";
import mappings from "../../data/daysMappings";
import BookingNotification from "../Notifications/BookingNotification";
import { useMediaQuery } from "react-responsive";
import { TitleBack } from "../TitleBack";
import Notification from "../Notifications/Notification";
import toastHelpers from "../../helpers/toastHelper";
import { Link } from "react-router-dom";
import apiPrivate from "../../apiPrivate";
import SignInPopUp from "../SignInUp/SignInPopUp";
import useLoaderContext from "../../hooks/useLoaderContext";
import useOverflowContext from "../../hooks/useOverflowContext";
import useErrorContext from "../../hooks/useErrorContext";

export default function Booking() {
    const { state } = useLocation();
    const course = state ? state.course : null;

    const { toastError } = toastHelpers;
    const { dispatch:loadingDispatch } = useLoaderContext();
    const { dispatch:overflowDispatch } = useOverflowContext();
    const { dispatch:errorDispatch } = useErrorContext();
    
    const [taughtCourses, setTaughtCourses] = useState([]);
    const [courses, setCourses] = useState([]);
    const [tutorInfo, setTutorInfo] = useState(null);
    const [tutorName, setTutorName] = useState("");;

    const { days, months } = mappings;

    const { token } = useAuthContext();
    const { tutorID } = useParams();
    
    const [date, setDate] = useState(null);

    const [availableBookings, setAvailableBookings] = useState(null);
    const [availableHours, setAvailableHours] = useState(null);
    const [availableHoursElement, setAvailableHoursElement] = useState(null);

    const [bookingSelected, setBookingSelected] = useState(null);

    const [courseSelected, setCourseSelected] = useState(null);
    const [coursesElement, setCoursesElement] = useState([]);
    const [search, setSearch] = useState("");
    const [refreshDates, setRefreshDates] = useState(false);

    const [bookingNotification, setBookingNotification] = useState({ show: false });
    const [successNotification, setSuccessNotification] = useState(false);
    const [message, setMessage] = useState({
        success: null,
        error: null
    })

    const isMedium = useMediaQuery({ query: '(max-width: 900px)' });

    // Fetch the tutor's info
    useEffect(() => {
        const getTutorInfo = async () => {
            try {
                loadingDispatch({ type: 'LOAD' });
                const { data } = await apiPrivate.get(`/tutor/getInfo?user_id=${tutorID}`);
                loadingDispatch({ type: 'NO_LOAD' });
        
                setTaughtCourses(data.taught_courses);
                setTutorInfo(data);
                setTutorName(`${data.user.first_name} ${data.user.last_name}`);
            } catch (error) {
                loadingDispatch({ type: 'NO_LOAD' });
                
                if (error.response.status === 404) {
                    errorDispatch({ type: '404_ERROR', isNetworkError: false });
                } else {
                    errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                }
            } 
        }
        
        getTutorInfo();
    }, [errorDispatch, loadingDispatch, tutorID]);

    // Freeze the screen if notification is shown
    useEffect(() => {
        if (bookingNotification.show) {
            overflowDispatch({ type: 'SET_FREEZE', payload: true });
        } else {
            overflowDispatch({ type: 'SET_FREEZE', payload: false });
        }
    }, [bookingNotification, overflowDispatch])

    useEffect(() => {
        // Get the available hours of the tutor
        const getTutorHours = async () => {
            try {
                loadingDispatch({ type: 'LOAD' });
                const queryParams = new URLSearchParams({
                    tutor_id: tutorInfo.id
                }).toString();
                
                const url = `/user/getHours?booked_only=False&${queryParams}`;
                const { data } = await apiPrivate.get(url);
                
                setAvailableBookings(data);
                loadingDispatch({ type: 'NO_LOAD' });
            } catch (error) {
                loadingDispatch({ type: 'NO_LOAD' });
                
            }
        };

        if (token && tutorInfo) getTutorHours();
    }, [token, tutorID, loadingDispatch, tutorInfo])

    // Select the intial course
    useEffect(() => {
        if (course) setCourseSelected({ id: course.id, name: course.name });
    }, [course])

    // Get the hours of the date specified
    useEffect(() => {
        // reset the hours selected
        if (date) setBookingSelected(null);

        if (availableBookings && date) {
            const newDate = new Date(date);
            const month = newDate.getMonth() + 1;
            const day = newDate.getDate();

            let days = {};
            for (let i = 0; i < availableBookings.length; i++) {
                // finding the month we need
                if (availableBookings[i].month === month) {
                    days = availableBookings[i].days;
                    break;
                }
            }

            // finding the hours we need
            const hours = days[day] ? days[day] : [];
            setAvailableHours(hours.map((hour) => {
                const day = new Date(hour.start_time);

                return {
                    hour: day.getHours(),
                    id:  hour.id
                }
            }))
        }
    }, [date, availableBookings]);

    // Set up the available hours element
    useEffect(() => {
        // Handle the select of chips
        function handleHourClick(event) {
            const hour = event.target.value;
            const id = event.target.id;

            if (bookingSelected && bookingSelected.hour === hour) {
                setBookingSelected(null);
            } else {
                setBookingSelected({ id, hour });
            }
        }

        if (availableHours) {
            setAvailableHoursElement(availableHours.map((slot, idx) => {
                const hour = slot.hour;
                return (
                    <button 
                        key={idx} 
                        id={slot.id} 
                        value={hour} 
                        className={`choice-chip-hour choice-chip${bookingSelected && Number(bookingSelected.hour) === Number(hour) ? " selected-chip" : ""}`}
                        onClick={handleHourClick}
                    >{`${hour}:00`}</button>
                )
            }))
        }
    }, [availableHours, bookingSelected]);

    // Courses elements to render
    useEffect(() => {
        setCoursesElement(() => courses.map((course, id) => {
             return (
                 <div className="courses-radio--container" key={id} onClick={handleCourse}>
                     <input 
                        type="radio" 
                        id={`${course.id}-${course.name}`} 
                        name="course" 
                        className="course-filter-radio" 
                        defaultChecked={courseSelected && course.id === courseSelected.id}
                    />
                     <label htmlFor={`${course.id}-${course.name}`}>{course.name}</label>
                 </div>
             )
         }))
     }, [courses, courseSelected]);

    // Handle the search
    function handleSearch(event) {
        setSearch(event.target.value);
    }

    useEffect(() => {
        setCourses(taughtCourses.filter((course) => {
            const courseName = course.name.toLowerCase();
            return courseName.includes(search.toLowerCase());
        }));
    }, [search, taughtCourses]);

    // Handle the selection of the course
    function handleCourse(event) {
        const value = event.target.id.split('-');
        const id = value[0];
        const name = value[1];
        
        setCourseSelected({ id, name });
    }

    useEffect(() => {
        if (message.error) {
            toastError(message.error);
        }
    }, [message, toastError]);

    const content = (
        <p>
            <span>Your booking request was sent to your tutor. You may monitor the progress of this booking </span>
            <Link to="/pending-bookings" className="notification-link">here</Link>
            <span>.</span>
        </p>
    )
    
    return (
        tutorInfo && 
        <div id="booking-page">
            {!token && 
                <SignInPopUp 
                    tutorID={tutorID}
                    purpose="book your session"
                />
            } 
            {bookingNotification.show && 
                <BookingNotification 
                    setBookingNotification={setBookingNotification}
                    id={bookingSelected.id}
                    tutor={tutorName}
                    course={courseSelected.name}
                    day={date}
                    hour={bookingSelected.hour}
                    isBooking={true}
                    bookingId={bookingSelected.id}
                    courseId={courseSelected.id}
                    setDate={setDate}
                    setSuccessNotification={setSuccessNotification}
                    message={message}
                    setMessage={setMessage}
                    hourlyRate={tutorInfo.rate}
                    setRefreshDates={setRefreshDates}
                    refreshDates={refreshDates}
                />}

            {successNotification &&
                <Notification 
                    handleConfirm={() => setSuccessNotification(false)}
                    title={"Booking Request Sent!"}
                    content={content}
                    doneButton={true}
                    isWarning={false}
                />
            }

            <TitleBack 
                title={!isMedium ? `Book your session with ${tutorName}` : tutorName}
                to={`/tutor/courses/${tutorID}`} 
            />
            <div id="booking--container">
                <div id="booking--calendar">
                    {date && <div className="all-bookings--current-day">
                        <span className="material-symbols-outlined" id="date-icon">
                            calendar_month
                        </span>

                        <h5>{`${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`}</h5>
                    </div>}
                    
                    <CalendarBooking 
                        date={date}
                        setDate={setDate}
                        isBooking={true}
                        tutorId={tutorInfo.id}
                        isTutor={false}
                    />

                    {date && courseSelected && bookingSelected && <button className="small-button" id="booking-button" onClick={() => setBookingNotification({ show: true })}>Book</button>}
                </div>
                {date && <div className="vertical-separator"></div>}

                {date && 
                <div id="booking--time">
                    {availableHours && availableHours.length !== 0 ?
                        <div className="booking--hours">
                            <div>
                                <h5 className="available--titles">Available Hours:</h5>

                                <div id="available-hours--container">
                                    {availableHoursElement}
                                </div>
                            </div>

                            <div className="booking--course">
                                <h5 className="available--titles">Available Courses:</h5>

                                <div id="courses-options--container" className="scrollable">
                                    <div id="search-bar-container--form" className={`sticky`}>
                                        <input id="search-course--form" placeholder="Search Courses" onChange={handleSearch} />
                                        <span className="material-symbols-outlined" id="search-icon--form">search</span>
                                    </div>

                                    <fieldset onChange={handleCourse}>
                                        {coursesElement}
                                    </fieldset>
                                </div>
                            </div>
                        </div> :
                        <p className="">There are no available hours on this day.</p>
                    }
                </div>}
            </div>
        </div>
    )
}