const halfStar = "half", fullStar = "full", emptyStar = "empty";

function prepareStarsDisplay(rating) {
    rating = Number(rating);
    let starsArr = [emptyStar, emptyStar, emptyStar, emptyStar, emptyStar];

    for (let i = 0; i < 5 && rating > 0; i++) {
        if (rating < 1) {
            starsArr[i] = halfStar;
        } else {
            starsArr[i] = fullStar;
        }

        rating -= 1;
    }

    return starsArr.map((starState, key) => {
                const starName = starState === halfStar ? "star_half" : "star";
                let starClass = "material-symbols-outlined";
                starClass += starState === fullStar ? " filled-star" : "";

                return <span key={key} className={starClass}>{starName}</span>;
            });
}

const ratingHelpers = {
    prepareStarsDisplay
};

export default ratingHelpers;