import { useRef } from "react";
import { faUserGraduate, faChalkboardUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInView } from "framer-motion";

export default function SmallIntro({ title, description }) {
    const titleRef = useRef(null);
    const barRef1 = useRef(null);
    
    const inView1 = useInView(barRef1, {
        threshold: 0.1,
        once: true
    });

    return (
        <div id="smallintro--container">
            <h1 className="small-intro--title">
                {title}
            </h1>
            <hr ref={titleRef}></hr>

            <div className="descriptions--container">
                <div className="description--container" style={{ textAlign: "start" }}>
                    <FontAwesomeIcon icon={faUserGraduate} className="about-icon" />
                    <div className={`green-bar${inView1 ? " expand" : ""}`} style={{ left: "95px" }} ref={barRef1}></div>
                    <p className="light-grey description--about">
                        <span className="green-p-about">{description[0]}</span>
                        <span>{description[1]}</span>
                    </p>
                </div>
                
                <div className="description--container" style={{ textAlign: "end" }}>
                    <p className="light-grey description--about">{description[2]}</p>
                    <div className={`green-bar${inView1 ? " expand" : ""}`} style={{ right: "120px" }}></div>
                    <FontAwesomeIcon icon={faChalkboardUser} className="about-icon" />
                </div>
            </div>
        </div>
    )
}
