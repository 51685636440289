import React, { createContext, useReducer } from "react"

export const BookingsContext = createContext();

const bookingsReducer = (state, action) => {
    switch(action.type) {
        case 'INIT_BOOKINGS':
            if (!state.tutorBookings && !action.payload.isUser) {
                return {
                    ...state,
                    tutorBookings: action.payload.newBookings
                }
            }

            if (!state.userBookings && action.payload.isUser) {
                return {
                    ...state,
                    userBookings: action.payload.newBookings
                }
            }
            return state;

        case 'FORCE_BOOKINGS':
            if (!action.payload.isUser) {
                return {
                    ...state,
                    tutorBookings: action.payload.newBookings
                }
            } else {
                return {
                    ...state,
                    userBookings: action.payload.newBookings
                }
            }

        case 'DELETE_BOOKING':
            const { day: newDay, bookingId, isTutorBookings } = action.payload;
            let newArr = isTutorBookings ? [...state.tutorBookings] : [...state.userBookings];
            const currDay = new Date(newDay);
            const currMonthIndex = newArr.findIndex(curr => curr.month === currDay.getMonth() + 1);
        
            const day = currDay.getDate();
            const daysArr = newArr[currMonthIndex].days[day] || []; // Ensure daysArr is defined
        
            // Filter out the booking with the matching ID
            const updatedDaysArr = daysArr.filter(booking => booking.id !== bookingId);
        
            // If there are no bookings left for that day, delete the day
            if (updatedDaysArr.length === 0) {
                delete newArr[currMonthIndex].days[day];
            } else {
                newArr[currMonthIndex].days[day] = updatedDaysArr;
            }
        
            return {
                ...state,
                [isTutorBookings ? 'tutorBookings' : 'userBookings']: newArr
            };
            
        case 'DELETE_BLOCKED_USER':
            // this case is only for the tutors (isTutorBookings = true)
            const userID = action.payload;
            let editedArr = [ ...state.tutorBookings ];
            let currMonthIdx = 0;

            for (let monthInfo of editedArr) {
                const currMonthDays = monthInfo.days; // we get: 25: [], 26: []...
                
                for (let currDay in currMonthDays) {
                    const currDayBookings = currMonthDays[currDay]; // we get the array [] (the bookings)
                    const editedBookings = currDayBookings.filter(booking => booking.user_details.id !== userID);
                    
                    if (editedBookings.length === 0) {
                        // remove the day altogther
                        delete editedArr[currMonthIdx].days[currDay];
                    }
                }

                currMonthIdx++;
            }
            
            return {
                ...state,
                tutorBookings: editedArr
            };

        case 'RESET_BOOKINGS':
            return {
                tutorBookings: null,
                userBookings: null
            };

        default:
            return state;
    }
} 

export const BookingsContextProvider = ({ children }) => {
    const [bookings, dispatch] = useReducer(bookingsReducer, {
        tutorBookings: null,
        userBookings: null
    });

    return (
        <BookingsContext.Provider value={{ ...bookings, dispatch }}>
            {children}
        </BookingsContext.Provider>
    )
}