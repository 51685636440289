import React from 'react';
import { Link } from 'react-router-dom';

export default function TermsOfServices() {
    return (
        <div className="privacy-policy--container">
            <iframe
                title="Terms and Conditions"
                src={require('../../htmls/Studytron Terms and Conditions.htm')}
                className="privacy-policy--html"
            />

            <Link to="/sign-up" className="small-button privacy-policy--button">Back to Sign Up</Link>
        </div>
    )
}