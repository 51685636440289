import React, { useEffect } from 'react';
import '../../styles/sign-in-up.css';
import { Link } from "react-router-dom";
import useLogin from '../../hooks/useLogin';
import BackArrow from '../BackArrow';
import validators from '../../helpers/validators';
import { useMediaQuery } from 'react-responsive';
import useOverflowContext from '../../hooks/useOverflowContext';

export default function SignInPage() {
    const { login, error, setError } = useLogin();
    const { isValidPassword, isValidUsername } = validators;

    const isSmallMobile = useMediaQuery({ query: "(max-width: 410px)" });
    const { dispatch:overflowDispatch } = useOverflowContext();

    const [formData, setFormData] = React.useState({
        login_credential: "",
        password: ""
    })

    // Unfreeze the screen if it was blocked
    useEffect(() => {  overflowDispatch({ type: 'SET_FREEZE', payload: false }) }, [overflowDispatch]);

    // Handle the form change
    function handleChange(event) {
        setError(null);
        setFormData((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value
            }
        })
    }

    // Handle the submission of the form
    async function handleSignIn() {
        if (isValidPassword(formData.password, "", {}, false) && isValidUsername(formData.login_credential, {})) {
            await login(formData);
        } else setError("Incorrect email/username or password.");
    }

    return (
        <div id="sign-up--container" className="sign-in--container">
            <BackArrow 
                to="/"
                additionalClass="absolute"
            />
            <div className='sign-in-up--background'>
                <div className="contact-section">
                    <div className="contact-header">
                        <h2>Sign In</h2>
                    </div>
                    <div className="form-container">
                        <div className="form-row">
                            <label htmlFor="login_credential" className="label">Credentials:</label>
                            <input 
                                type="login_credential" 
                                id="login_credential" 
                                name="login_credential" 
                                placeholder={`Enter${isSmallMobile ? "" : " your"} username, email, or phone${isSmallMobile ? "" : " number"}`} 
                                className={`sign-up--elements${error ? " error-borders" : ""}`} 
                                onChange={handleChange} 
                            />
                        </div>
                        <div className="form-row" style={{ marginBottom: "7px" }}>
                            <label htmlFor="password" className="label">Password:</label>
                            <input 
                                type="password" 
                                id="password" 
                                name="password" 
                                placeholder={`Enter${isSmallMobile ? "" : " your"} password`}
                                className={`sign-up--elements${error ? " error-borders" : ""}`}  
                                onChange={handleChange} 
                            />
                        </div>

                        <div className="forgot-password--button">
                            <Link to="/forgot-password">Forgot password?</Link>
                        </div>
                        
                        {error && <p className="error-p" style={{ marginTop: "-13px", marginBottom: "-10px" }}>{error}</p>}
                        <div className="submit--sign-in">
                            <div className="form-row">
                                <button type="submit" className="submit-button" onClick={handleSignIn}>Sign In</button>
                            </div>
                            <div id="sign-in-up-switch">
                                <p>Don't have an account?</p>
                                <Link to="/sign-up">Sign up.</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
