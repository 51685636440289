import React, { useEffect, useState } from 'react';
import useMajorsContext from '../../hooks/useMajorsContext';
import { Link } from 'react-router-dom';
import Box from '../Box';

export default function MajorsCarousel() {
    const { majors } = useMajorsContext();
    const [majorsElement, setMajorsElement] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (majors) {
            setMajorsElement(majors.map((major, idx) => {
                let additionalClass = "";

                if (idx === activeIndex - 1 || idx === activeIndex + 1 || idx === activeIndex - 2 || idx === activeIndex + 2) {
                    additionalClass = " prev-next-major--carousel";
                } else if (idx === activeIndex) {
                    additionalClass = " current-major--carousel"
                }

                return (
                    <Link 
                        to="/courses"
                        state={{ major: major, majorId: major.id }}
                        key={idx}
                    >
                        <Box 
                            name={major.name}
                            abbreviation={major.code}
                            elementName={`Course${major.num_courses !== 1 ? "s" : ""}`}
                            elements={major.num_courses}
                            extraClass={additionalClass}
                            setActiveIndex={setActiveIndex}
                            idx={idx}
                        />
                    </Link>
                )
            }))

        }
    }, [majors, activeIndex])

    return (
        <div id="majors-carousel--bg">
            <div className="majors-carousel--container">
                {majorsElement}
            </div>
        </div>
    )
}