import { AuthContextProvider } from './context/AuthContext';
import { LoaderContextProvider } from './context/LoaderContext';
import { OverflowContextProvider } from './context/OverflowContext';
import { BookingsContextProvider } from './context/BookingsContext';
import { UserInfoContextProvider } from './context/UserInfoContext';
import { PendingsContextProvider } from './context/PendingsContext';
import { MajorsContextProvider } from './context/MajorsContext';
import { LanguagesContextProvider } from './context/LanguagesContext';
import { CoursesContextProvider } from './context/CoursesContext';
import { ErrorContextProvider } from './context/ErrorContext';
import { SubscriptionContextProvider } from './context/SubscriptionContext';

export const CombinedContextProvider = ({ children }) => {
  return (
    <UserInfoContextProvider>
      <AuthContextProvider>
        <LoaderContextProvider>
          <ErrorContextProvider>
            <OverflowContextProvider>
              <BookingsContextProvider>
                <PendingsContextProvider>
                  <MajorsContextProvider>
                    <LanguagesContextProvider>
                      <CoursesContextProvider>
                        <SubscriptionContextProvider>
                          {children}
                        </SubscriptionContextProvider>
                      </CoursesContextProvider>
                    </LanguagesContextProvider>
                  </MajorsContextProvider>
                </PendingsContextProvider>
              </BookingsContextProvider>
            </OverflowContextProvider>
          </ErrorContextProvider>
        </LoaderContextProvider>
      </AuthContextProvider>
    </UserInfoContextProvider>
  );
};
