import useAuthContext from "./useAuthContext";
import { useNavigate } from 'react-router-dom';
import useLoaderContext from "./useLoaderContext";
import useUserInfoContext from "./useUserInfoContext";
import useBookingsContext from "./useBookingsContext";
import api from "../api";
import useErrorContext from "./useErrorContext";
import useSubscriptionContext from "./useSubscriptionContext";

export default function useLogout() {
    const { setToken } = useAuthContext();
    const { dispatch:loaderDispatch } = useLoaderContext();
    const { dispatch:userInfoDispatch } = useUserInfoContext();
    const { dispatch:bookingsDispatch } = useBookingsContext();
    const { dispatch:errorDispatch } = useErrorContext();
    const { dispatch:subscriptionDispatch } = useSubscriptionContext();

    const navigate = useNavigate();

    // Remove the refresh token from the cookies
    const signOut = async () => {
        try {
            await api.post("/logout");
        } catch (error) {
            errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
            
        }
    }
    
    const logout = () => {
        // remove user from storage=
        loaderDispatch({ type: 'LOAD' });

        setToken(null);
        signOut();

        userInfoDispatch({ type: 'RESET_USERINFO' });
        bookingsDispatch({ type: 'RESET_BOOKINGS' });
        subscriptionDispatch({ type: 'RESET_SUBSCRIPTION' });

        setTimeout(() => {
            navigate('/');
            loaderDispatch({ type: 'NO_LOAD' });
        }, 1000);
    }

    return { logout };
}