const faqQuestions = [
    {
        prompt: "Is there a subscription fee for students?",
        answer: "There is no subscription fee for students. You only pay for the individual tutoring sessions that you book."
    },
    {
        prompt: "What is the subscription fee for tutors?",
        answer: "Tutors pay a monthly subscription fee, through Whish, to be listed on the platform, and no extra commission fees are further deducted per session. This fee allows them to showcase their profile and receive booking requests from students."
    },
    {
        prompt: "Can I extend my subscription by buying another plan?",
        answer: "Yes, you can! If you already have an active subscription and decide to purchase an additional plan, the duration of the new plan will be added on top of your current validity."
    },
    {
        prompt: "How do I book a session with a tutor?",
        answer: "After signing up as a student, browse available tutors based on course, rating, or language preference. Once you find a tutor, check their availability and send a booking request. You can monitor its status in the pending section."
    },
    {
        prompt: "Can I reschedule or cancel a session?",
        answer: "Yes, both students and tutors can reschedule or cancel a session from their dashboard. However, out of respect for each other's time, we encourage both parties to avoid canceling unless absolutely necessary. Please try to provide early notice if a conflict arises."
    },
    {
        prompt: "Is this website restricted to LAU students?",
        answer: "Yes, the courses and majors available are specifically tailored for LAU students. We may expand and include other universities in the future."
    }
];

export default faqQuestions;