import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const FontLoader = () => {
  const location = useLocation();

    useEffect(() => {
      document.body.classList.add('font-not-loaded');
      document.body.classList.remove('font-loaded');

      document.fonts.load('1em "Material Symbols Outlined"').then(() => {
        document.body.classList.add('font-loaded');
        document.body.classList.remove('font-not-loaded');
      }).catch(() => {
        document.body.classList.add('font-not-loaded');
        document.body.classList.remove('font-loaded');
      });
    }, [location]);

    return null;
};

export default FontLoader;
