import { useState, useEffect, useCallback, memo } from "react";
import "../../styles/styles.css";
import MobileFilters from "./MobileFilters";
import { useMediaQuery } from "react-responsive";
import api from "../../api.js";
import useCoursesContext from "../../hooks/useCoursesContext";
import useLanguagesContext from "../../hooks/useLanguagesContext.js";
import useErrorContext from "../../hooks/useErrorContext.js";
import useOverflowContext from "../../hooks/useOverflowContext.js";
import useLoaderContext from "../../hooks/useLoaderContext.js";

export const TutorsSearchBar = memo(({ formData, setFormData, showFilters, setShowFilters }) => {
    const [coursesOptions, setCoursesOptions] = useState([]);
    const [ratingsOptions, setRatingsOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [rateOptions, setRateOptions] = useState([]);
    
    const [ratingSelected, setRatingSelected] = useState([true, false, false, false, false, false]);
    const [rateSelected, setRateSelected] = useState([true, false, false, false, false, false, false, false]);
    
    const [animationClass, setAnimationClass] = useState("fade-in");

    const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    const { courses, dispatch:coursesDispatch } = useCoursesContext();
    const { languages, dispatch:languagesDispatch } = useLanguagesContext();
    const { dispatch:errorDispatch } = useErrorContext();
    const { dispatch:overflowDispatch } = useOverflowContext();
    const { dispatch:loadingDispatch } = useLoaderContext();

     // Fetch languages and courses
     useEffect(() => {
        // Get the courses from the database
        const fetchCourses = async () => {
            try {
                if (!showFilters) loadingDispatch({ type: 'LOAD' });

                const { data } = await api.get('/getAllCourses/');
                coursesDispatch({ type: 'SET_COURSES', payload: data });

                if (!showFilters) loadingDispatch({ type: 'NO_LOAD' });
            } catch (error) {
                loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
            }
        }

        // Get the languages from the database
        const fetchLanguages = async () => {
            try {
                const { data } = await api.get('/getLanguages/');
                languagesDispatch({ type: 'SET_LANGUAGES', payload: data });
            } catch (error) {
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
            }
        }

        if (!languages) fetchLanguages();
        if (!courses) fetchCourses();

        setRateOptions(() => {
            return [<option key={7} value="40">40 and Below</option>,
                    <option key={6} value="35">35 and Below</option>,
                    <option key={5} value="30">30 and Below</option>,
                    <option key={4} value="25">25 and Below</option>,
                    <option key={3} value="20">20 and Below</option>,
                    <option key={2} value="15">15 and Below</option>,
                    <option key={1} value="10">10 and Below</option>]
        })

        setRatingsOptions(() => {
            return [<option key={5} value="5">5 Only</option>,
                    <option key={4} value="4">4 and Above</option>,
                    <option key={3} value="3">3 and Above</option>,
                    <option key={2} value="2">2 and Above</option>,
                    <option key={1} value="1">1 and Above</option>];
        }) 
    }, [courses, languages, errorDispatch, coursesDispatch, languagesDispatch, loadingDispatch, showFilters])

    // Set the courses options
    useEffect(() => {
        if (courses) {
            setCoursesOptions(() => {
                return courses.map((course, id) => {
                    return <option key={id} value={`${course.id}-${course.name}`} >{course.name}</option>
                })
            })
        }
    }, [courses])
    
    // Set the languages options
    useEffect(() => {
        if (languages) {
            setLanguageOptions(() => {
                return languages.map((language, id) => <option key={id} value={`${language.id}-${language.name}`}>{language.name}</option>);
            })
        }
    }, [languages]);

    // Handle the form change
    const handleChange = useCallback((event) => {
        if (isMobile) {
            if (event.target.name === "rating") {
                setRatingSelected((prev) => prev.map((rating,id) => {
                    if (Number(id) === Number(event.target.id)) return true;
                    return false;
                }))
            } else if (event.target.name === "rate") {
                setRateSelected((prev) => prev.map((rate,id) => {
                    if (Number(id) === Number(event.target.id)) return true;
                    return false;
                }))
            }
        }

        setFormData(prevData => {
            return {
                ...prevData,
                [event.target.name]: event.target.value
            }
        })
    }, [isMobile, setFormData]);

    // Handle the click of the filter
    function handleFilter() {
        setAnimationClass("fade-out--filters");
        if (showFilters) {
            setTimeout(() => {
                overflowDispatch({ type: 'SET_FREEZE', payload: false });
                setShowFilters(false);
                setAnimationClass("fade-in");
            }, 300);
        } else {
            setAnimationClass("fade-in");
            overflowDispatch({ type: 'SET_FREEZE', payload: true });
            setShowFilters(true);
        }
    }

    // Clear entry
    function handleClearEntry() {
        setFormData(prev => {
            return {
                ...prev,
                searchEntry: ""
            }
        })
    }

    return (
        <div 
            className="search--container" 
            id="tutors-search--container" 
            style={{ 
                transform: showFilters ? "none" : "translate(-50%,-50%)",
                left: showFilters ? "0" : "50%"
            }}
        >
            <div id="search-bar">
                <input type="text" name="searchEntry" placeholder="Search Tutor" onChange={handleChange} value={formData.searchEntry} />
                {formData.searchEntry !== "" ?
                    <span 
                        className="material-symbols-outlined icon-search-bar" 
                        id="close-icon"
                        onClick={handleClearEntry}
                    >close</span>
                    :
                    <span className="material-symbols-outlined icon-search-bar">search</span>
                }
            </div>
            {isMobile && <span id="filter-icon" className="material-symbols-outlined" onClick={handleFilter}>tune</span>}

            {showFilters && 
                <MobileFilters 
                    courses={courses}
                    languages={languages}
                    handleFilter={handleFilter}
                    handleChange={handleChange}
                    formData={formData}
                    ratingSelected={ratingSelected}
                    rateSelected={rateSelected}
                    animationClass={animationClass}
                />
            }

            {!isMobile && <form id="tutors-selects--container">
                <div className="select--container tutors-select--container">
                    <select
                        value={formData.course}
                        onChange={handleChange}
                        className="select-element"
                        id="select-tutor"
                        name="course"
                    >
                        <option value="">All Courses</option>
                        {coursesOptions}
                    </select>
                </div>

                <div className="select--container tutors-select--container">
                    <select
                        value={formData.rating}
                        onChange={handleChange}
                        className="select-element"
                        id="select-tutor"
                        name="rating"
                    >
                        <option value="">All Ratings</option>
                        {ratingsOptions}
                    </select>
                </div>

                <div className="select--container tutors-select--container">
                    <select
                        value={formData.language}
                        onChange={handleChange}
                        className="select-element"
                        id="select-tutor"
                        name="language"
                    >
                        <option value="">All Languages</option>
                        {languageOptions}
                    </select>
                </div>

                <div className="select--container tutors-select--container">
                    <select
                        value={formData.hourlyRate}
                        onChange={handleChange}
                        className="select-element"
                        id="select-tutor"
                        name="rate"
                    >
                        <option value="">All Hourly Rates</option>
                        {rateOptions}
                    </select>
                </div>
            </form>}
        </div>
    )
})