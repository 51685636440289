import { useState, useEffect, useRef, createRef } from "react";
import { TitleBack } from "../TitleBack"
import CalendarBooking from "./CalendarBooking"
import mappings from "../../data/daysMappings";
import useUserInfoContext from "../../hooks/useUserInfoContext";
import usePendingsContext from "../../hooks/usePendingsContext";
import { v4 as uuidv4 } from 'uuid';
import dateHelpers from "../../helpers/dateHelpers";
import BookingInfoBox from "./BookingInfoBox";
import toastHelpers from "../../helpers/toastHelper";
import { useNavigate } from "react-router-dom";
import useAuthContext from "../../hooks/useAuthContext";
import SignInPopUp from "../SignInUp/SignInPopUp";

export default function PendingBookings() {
    const [date, setDate] = useState(null);
    const { days:daysMappings, months } = mappings;
    const { areDatesEqual } = dateHelpers;
    const { toastSuccess, toastError } = toastHelpers;

    const [pendingsElement, setPendingsElement] = useState([]);
    const { isTutor } = useUserInfoContext();
    const { userPendings, tutorPendings } = usePendingsContext(); 
    const [pendings, setPendings] = useState(null);
    const [isTutorPendings, setIsTutoringPendings] = useState(null); // for the tutor to switch between his students and his tutors (true if it's his sessions to teach)

    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [refreshDates, setRefreshDates] = useState(false);

    const { token } = useAuthContext();
    const navigate = useNavigate();
    

    const references = useRef({});
    const [message, setMessage] = useState({
        success: null,
        error: null
    });

    useEffect(() => {
        if (isTutor !== null) setIsTutoringPendings(isTutor);
    }, [isTutor])

    // Check if it's a tutor or student pendings to know which array to work with
    useEffect(() => {
        if (isTutorPendings && tutorPendings) {
            setPendings(tutorPendings);
        } else if (!isTutorPendings && userPendings) {
            setPendings(userPendings);
        }
    }, [isTutorPendings, userPendings, tutorPendings]);

    // Construct the pendings elements
    useEffect(() => {
        if (pendings) {
            let currDate = null;
            let currHour = null;
            const bookingArr = [];
            let bookingBoxes = [];

            for (const pending of pendings) {
                const newDate = new Date(pending.booking.start_time);
                currHour = null;

                if (!currDate || !areDatesEqual(newDate, currDate)) {
                    const currWeekDay = daysMappings[newDate.getDay()];
                    const currDay = newDate.getDate();
                    const currMonth = months[newDate.getMonth()];
                    const currYear = newDate.getFullYear();

                    currDate = new Date(newDate);

                    // add a new reference to this day
                    currDate.setHours(0);
                    if (!references[currDate]) {
                        references[currDate] = createRef();
                    }

                    if (bookingBoxes.length !== 0) {
                        bookingArr.push(
                            <div key={uuidv4()} className="booking-boxes--container">
                              {bookingBoxes}
                            </div>
                        );
                        bookingBoxes = [];
                    }

                    bookingArr.push(<h3 key={uuidv4()} className="all-bookings--day" ref={references[currDate]}>{`${currWeekDay} ${currDay} ${currMonth}, ${currYear}`}</h3>);
                }

                if (!currHour || currHour !== newDate.getHours()) {
                    currHour = newDate.getHours();

                    if (bookingBoxes.length !== 0) {
                        bookingArr.push(
                            <div key={uuidv4()} className="booking-boxes--container">
                              {bookingBoxes}
                            </div>
                        );
                        bookingBoxes = [];
                    }

                    bookingArr.push(<h4 key={uuidv4()} className="all-bookings--hour">{`${currHour}:00`}</h4>);
                }

                const user = pending.user_details ? pending.user_details : pending.tutor_details;
                const fullName = `${user.first_name} ${user.last_name}`;
                
                // pushing the booking boxes
                bookingBoxes.push(
                <BookingInfoBox 
                    key={uuidv4()}
                    name={fullName}
                    courseDetails={pending.course}
                    date={pending.booking.start_time}
                    bookingId={pending.booking.id}
                    isPending={true}
                    isTutorBookings={isTutorPendings}
                    userId={user.id}
                    setDate={setDate}
                    hourlyRate={pending.tutor_details ? pending.tutor_details.rate : null}
                    setMessage={setMessage}
                    showMoreInfo={showMoreInfo}
                    setShowMoreInfo={setShowMoreInfo}
                    profilePictureURL={user.profile_picture}
                    phone_number={user.profile.phone_number}
                    email={user.email}
                    refreshDates={refreshDates}
                    setRefreshDates={setRefreshDates}
                />)
            }

            bookingArr.push(
                <div key={uuidv4()} className="booking-boxes--container">
                  {bookingBoxes}
                </div>
            );

            setPendingsElement(bookingArr);
        }
    }, [pendings, daysMappings, isTutorPendings, months, areDatesEqual, message, showMoreInfo, refreshDates]);

    // Scroll to the correct day every time the date is changed
    useEffect(() => {
        if (date && references[date] && references[date].current) {
            const element = references[date].current;
            const offsetTop = element.getBoundingClientRect().top + window.scrollY;
            const offset = 110;
            window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
        }
    }, [date])

    // For sending notifications when something is updated
    useEffect(() => {
        if (message.success) {
            toastSuccess(message.success);
        } else {
            toastError(message.error);
        }
    }, [message, toastSuccess, toastError])

    return (
        <div id="all-bookings--container" onClick={() => setShowMoreInfo(false)}>  
            {!token &&
            <SignInPopUp 
                purpose="access your bookings"
                onClose={() => navigate("/")}
            />} 

            <TitleBack 
                title={"Pending Bookings"}
            />

            <div id="all-bookings--box">
                <CalendarBooking 
                    date={date}
                    setDate={setDate}
                    isBooking={false}
                    addDate={true}
                    isPending={true}
                    isTutorBookings={isTutorPendings}
                    setIsTutoringBookings={setIsTutoringPendings}
                    refreshDates={refreshDates}
                    setRefreshDates={setRefreshDates}
                />

                <div id="all-bookings--content">
                    {pendingsElement}
                    {pendingsElement.length === 0 && date && <h3 className="no-bookings-message">You have no pending bookings yet.</h3>}
                </div>
            </div>
        </div>
    )
}