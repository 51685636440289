import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import BackArrow from "../BackArrow";
import validators from "../../helpers/validators";
import api from "../../api.js";
import useLoaderContext from "../../hooks/useLoaderContext.js";
import toastHelpers from "../../helpers/toastHelper.js";
import useErrorContext from "../../hooks/useErrorContext.js";

export default function NewPassword() {
    const [error, setError] = useState(null);
    const location = useLocation();
    const email = location.state ? location.state.email : null;
    const { isValidPassword } = validators;
    const navigate = useNavigate();

    const { dispatch:loadingDispatch } = useLoaderContext();
    const { dispatch:errorDispatch } = useErrorContext();

    const { toastError } = toastHelpers;

    const [formData, setFormData] = useState({
        password: "",
        confirm_password: ""
    });

    useEffect(() => {
        if (!email) {
            errorDispatch({ type: '404_ERROR' }); 
        }
    }, [email, errorDispatch])

    // API to submit the new password
    const changeForgottenPassword = async () => {
        try {
            await api.post("/user/changeForgottenPassword", {
                new_password: formData.password,
                email
            });
        } catch (error) {
            loadingDispatch({ type: 'NO_LOAD' });
            toastError("An error occurred. Please try again.");
        }
    }

    // Handle form change
    function handleChange(event) {
        setError(null);
        setFormData(prev => {
            return {
                ...prev,
                [event.target.name]: event.target.value
            }
        })
    }
    
    // Handle the submission of the form
    async function handleSubmit(event) {
        event.preventDefault();

        const err = {}
        if (isValidPassword(formData.password, formData.confirm_password, err, true)) {
            await changeForgottenPassword();
            navigate('/sign-in')
        } else {
            setError(err);
        }
    }

    return (
        <div id="sign-up--container">
            <BackArrow 
                to="/forgot-password"
                additionalClass="absolute"
            />
            <div className='sign-in-up--background' style={{ animation: "none" }}>
                <form className="contact-section" style={{ animation: "none", opacity: "1" }}>
                    <div className="contact-header">
                        <h2 id="reset-password--title">Reset Password</h2>
                    </div>

                    <div className="form-container">
                        <div className="form-row">
                            <label htmlFor="password" className="label">New Password:</label>
                            <input 
                                type="password" 
                                id="password" 
                                name="password" 
                                placeholder="Enter your new password" 
                                className={`sign-up--elements${error ? " error-borders" : ""}`} 
                                onChange={handleChange}
                                value={formData.password}
                            />
                        </div>

                        <div className="form-row">
                            <label htmlFor="password" className="label">Confirm Password:</label>
                            <input 
                                type="password" 
                                id="confirm_password" 
                                name="confirm_password" 
                                placeholder="Enter your new password" 
                                className={`sign-up--elements${error ? " error-borders" : ""}`} 
                                onChange={handleChange}
                                value={formData.confirm_password}
                            />
                        </div>
                        {error && <p className="error-p">{error.password}</p>}

                        <button 
                            type="submit" 
                            className="submit-button" 
                            onClick={handleSubmit}
                            style={{ marginTop: "25px" }}
                        >Reset Password</button>
                    </div>
                </form>
            </div>
        </div>
    )
}