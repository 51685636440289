import React, { useEffect, useState } from "react";
import useSubscriptionContext from "../../hooks/useSubscriptionContext";
import Notification from "../Notifications/Notification";
import apiPrivate from "../../apiPrivate";
import useLoaderContext from "../../hooks/useLoaderContext";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import useUserInfoContext from "../../hooks/useUserInfoContext";
import useAuthContext from "../../hooks/useAuthContext";
import useErrorContext from "../../hooks/useErrorContext";
import toastHelpers from "../../helpers/toastHelper";

export default function PackageContainer({ subscriptionTitle, subscriptionPrice, subscriptionDuration, subscriptionDurationText, subscriptionDescription, prevRoute }) {
    const { isTrialUsed, isAccountActivated, remainingDays, dispatch:subscriptionDispatch } = useSubscriptionContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    const { dispatch:errorDispatch } = useErrorContext();
    const { token } = useAuthContext();
    const { user } = useUserInfoContext();
    
    const [showNotification, setShowNotification] = useState(false);
    const { toastError } = toastHelpers;

    const navigate = useNavigate();

    const invoice = subscriptionDuration === 1 ? "Studytron Monthly Tutor Subscription" : "Studytron Semester Tutor Subscription";
    
    // Calculate the valid until date
    const [validUntil, setValidUntil] = useState(new Date());
    useEffect(() => {
        function formatDate(date) {
            const day = String(date.getDate()).padStart(2, '0'); 
            const month = String(date.getMonth() + 1).padStart(2, '0'); 
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }

        let updatedDate = new Date();
        if (isTrialUsed) {
            updatedDate.setDate(updatedDate.getDate() + subscriptionDuration * 30);

            if (isAccountActivated) {
                updatedDate.setDate(updatedDate.getDate() + (remainingDays - 1));
            }
        }

        setValidUntil(formatDate(updatedDate));
    }, [isAccountActivated, remainingDays, subscriptionDuration, isTrialUsed]);

    // Handle upgrade
    const confirmUpgrade = async () => {
        try {
            loadingDispatch({ type: 'LOAD' });
            const { data } = await api.post("/sendPaymentRequest", {
                invoice,
                amount: subscriptionPrice,
                duration: subscriptionDuration,
                tutor_id: user.id
            });            
            
            loadingDispatch({ type: 'NO_LOAD' });
            window.open(data.response.data.collectUrl);
        } catch (error) {
            loadingDispatch({ type: 'NO_LOAD' });
            errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
            
        }
    }

    // Handle confirm of free trial
    const confirmFreeTrial = async () => {
        try {
            loadingDispatch({ type: 'LOAD' });
            await apiPrivate.post("/activateFreeTrial");
            subscriptionDispatch({ type: 'SET_STATUS', payload: { account_is_activated: true, trial_was_used: true }});
            subscriptionDispatch({ type: 'SET_REMAINING_DAYS', payload: { days_left: 14 }});
            loadingDispatch({ type: 'NO_LOAD' });
            navigate(prevRoute);
        } catch (error) {
            loadingDispatch({ type: 'NO_LOAD' });
            toastError("An error occurred. Please try again.");
        }
    }

    // Handle click of the plan
    function handleClick() {
        if (token && !isTrialUsed) {
            // the user is signed in
            setShowNotification(true);
        } else if (token) {
            // the user is signed in
            confirmUpgrade();
        } else {
            // the user is not signed in
            navigate("/sign-in");
        }
    }
    
    return (
        <div className="package--container">
            {showNotification && 
                <Notification 
                    title={"Activate Free Trial?"}
                    content={"Are you sure you want to activate your 14-day free trial? No credit card is needed for this process."}
                    handleConfirm={confirmFreeTrial}
                    handleCancel={() => setShowNotification(false)}
                />
            }
            <h3>{subscriptionTitle}</h3>

            <div className="subscription-price--container">
                <h2>${subscriptionPrice}</h2>
                <p>{subscriptionDurationText}</p>
            </div>

            <p>{subscriptionDescription}</p>

            <div className="get-plan--container">
                <button onClick={handleClick}>{isTrialUsed ? "Get Plan" : "Start 14-Day Free Trial"}</button>
                <p className="no-credit-card-text">
                    {`${!isTrialUsed ? 
                        "No credit card required" 
                        : 
                        `Valid until ${validUntil}`}
                    `}
                </p>
            </div>
        </div>
    )
}